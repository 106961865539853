export class Constraint {
   Temperature: string;
   Pressure: string;
   Shutoff: string;
   FlowDrop: string;

   constructor() {
      this.Temperature = "";
      this.Pressure = "";
      this.Shutoff = "";
      this.FlowDrop = "";
   }
}