import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { IProductLine } from 'src/app/shared/models/productline-data.model';
import { CommonApiService } from '../common-api.service';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})

export class ProductLineTreeService {
  private config: Config;
  public productLines: Observable<IProductLine[]>;

  constructor(private commonApiService: CommonApiService) {
    this.config = new Config();
    this.getProductLineTreeData();
  }

  getProductLineTreeData(): Observable<IProductLine[]> {
    return this.productLines = this.commonApiService.get(this.config.GET_PROJECTTAG_API).pipe(shareReplay());
  }

  getInflateProductLines(productLines: IProductLine[] = [], leafProductList: IProductLine[] = []): IProductLine[] {
    productLines.forEach((productLine: IProductLine) => {
      if (productLine.children?.length == 0) {
        leafProductList.push(productLine)
      } else {
        this.getInflateProductLines(productLine.children, leafProductList);
      }
    });

    return leafProductList;
  }

}