import { OnInit, TemplateRef, ViewContainerRef, Directive } from '@angular/core';
import { AbstractSecurity } from '../models/abstract-security';
import { SecurityService } from '../services/security.service';

@Directive()
export abstract class AbstractSecurityDirective extends AbstractSecurity implements OnInit {

  private hasView = false;
  abstract allowed(): boolean;

  changed(): void {
    const allowed = this.allowed();
    if (allowed && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!allowed && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }

  protected constructor(securityService: SecurityService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef) {
    super(securityService);
  }

  ngOnInit(): void {
    this.listenToChangeUser();
  }
}
