<div>
    <div class="flex flex-col w-full h-full gap-2 px-2 overflow-y-auto scrollbar max-h-72">
        @for (searchItem of searchResults; track $index) {
        <a
            class="flex items-center justify-between w-full p-3 rounded-lg hover:bg-[#F3F3F3] tranisiton-all hover:transition-all group">
            <div class="flex flex-col w-fit shrink-0">
                <div class="flex gap-1">
                    <fa-icon [icon]="faClock" class="mr-1 text-neutral-400"></fa-icon>
                    <div class="text-xs 2xl:text-sm font-semibold text-black">
                        {{searchItem.searchQuery.searchField | itemNames }}
                    </div>
                    <div class="text-black font-bold">.</div>
                    <div class="text-xs 2xl:text-sm font-normal text-neutral-500">
                        {{searchItem.searchQuery.searchText}}
                    </div>
                </div>

            </div>
            <div class="items-center hidden w-fit gap-x-8 group-hover:flex">
                <div class="text-xs font-semibold text-emerald-700 hover:underline cursor-pointer"
                    (click)="onSearch($index, 'applySearch')">
                    <fa-icon [icon]="faSearch" class="mr-0.5"></fa-icon>
                    Apply Search
                </div>

                <div class="text-xs font-semibold text-emerald-700 hover:underline cursor-pointer"
                    (click)="onSearch($index, 'editSearch')">
                    <fa-icon [icon]="faEdit" class="mr-0.5"></fa-icon>
                    Edit this search
                </div>
            </div>
        </a>
        }
    </div>
</div>