import { Injectable } from '@angular/core';
import { AbstractSecurity } from '../models/abstract-security';
import { SecurityService } from './security.service';
import { ModuleParams } from '../models/module-params';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends AbstractSecurity {

  constructor(securityService: SecurityService) {
    super(securityService);
    this.listenToChangeUser();
  }

  changed(): void { }

  public hasPermission(permissionName: string): boolean {
    return this.user.hasPermission(permissionName);
  }

  public hasModuleEditPermission(moduleType: string, xRefId: number): boolean;
  public hasModuleEditPermission(moduleType: string, xRefId: number, officeName: string): boolean;
  public hasModuleEditPermission(moduleType: string, xRefId: number, officeName?: string): boolean
  {
    const moduleParams = new ModuleParams(moduleType, xRefId, officeName!);
    return this.user.hasModulePermission(moduleParams, true);
  }

  public hasModuleReadPermission(moduleType: string, xRefId: number): boolean;
  public hasModuleReadPermission(moduleType: string, xRefId: number, officeName: string): boolean;
  public hasModuleReadPermission(moduleType: string, xRefId: number, officeName?: string): boolean
  {
    const moduleParams = new ModuleParams(moduleType, xRefId, officeName!);
    return this.user.hasModulePermission(moduleParams, false);
  }

  public isAuthenticated(): boolean {
    return this.user.isAuthenticated();
  }

}
