import { OnInit } from '@angular/core';
import { UserInfo } from './user-info';
import { SecurityService } from '../services/security.service';

export abstract class AbstractSecurity {
  user: UserInfo = new UserInfo();

  abstract changed(): void;

  protected constructor(private ss: SecurityService) { }

  listenToChangeUser(): void {
    this.ss.onChangeUser().subscribe(
      user => {
        this.user = user;
        this.changed();
      },
      error => {
        console.error(error);
        this.user = new UserInfo();
        this.changed();
      }
    );
  }
}
