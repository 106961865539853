import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractSecurityDirective } from './abstract-security.directive';
import { SecurityService } from '../services/security.service';

@Directive({
  selector: '[emrIsAuthenticated]'
})
export class IsAuthenticatedDirective extends AbstractSecurityDirective {

  constructor(securityService: SecurityService, templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(securityService, templateRef, viewContainerRef);
  }

  allowed(): boolean {
    return this.user.isAuthenticated();
  }
}
