import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonUtilityLibraryService } from 'src/app/shared/library/common-utility-library.service';
import { Router } from '@angular/router';
import { Construction, } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { CallBackAction } from '../../enums/buildassembly';
import { SizeValveService } from 'src/app/pages/tag-workspace/store/services/state/size-valve/size-valve.service';
import { BuildAssemblyDataService } from 'src/app/pages/tag-workspace/store/services/data/build-assembly/build-assembly-data.service';
import { BuildAssemblyService } from 'src/app/pages/tag-workspace/store/services/state/build-assembly/build-assembly.service';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  sessionId$: BehaviorSubject<string> = new BehaviorSubject("");
  successMsgTitle: string = "Save message\n" + "Your data saved Succesfully";
  tagWorkspaceNavigation = new Subject();
  tagWorkspaceNavigation$ = this.tagWorkspaceNavigation.asObservable();

  constructor(
    private buildAssemblyDataService: BuildAssemblyDataService, 
    private buildAssemblyService: BuildAssemblyService,
    private _sizeValveService: SizeValveService,
    public culibrary: CommonUtilityLibraryService,
    public router: Router) { }

  public updateTagRev = new Subject();
  updateTagRev$ = this.updateTagRev.asObservable();
  updateBreadcrum(rev: any) {
    this.updateTagRev.next(rev);
  }

  headerLogoClicked(url: string){
    this.tagWorkspaceNavigation.next(url);
  }

  saveBuildAssembly(callbackAction: CallBackAction,  page?: string) {
    let construction: Construction = JSON.parse(sessionStorage.getItem("activeConstruction")!);
    construction = this.buildAssemblyService.reduceConfigItPayLoad(construction);
    const saveValveSizingRequest = this._sizeValveService.getSaveValveSizingRequest(
      construction.tagIdentifier.constructionId,
      construction.tagIdentifier.projectId,
      construction.tagIdentifier.tagId
    );
    construction.saveValveSizingRequest = saveValveSizingRequest;
    
    construction.callbackAction = callbackAction;

    this.buildAssemblyDataService.saveBuildAssembly(construction).subscribe({
      next: (res: any) => {
        construction.isBuildAssemblyModified = false;
        sessionStorage.setItem("activeConstruction", JSON.stringify(construction));

        this.culibrary.showSnackbar(this.successMsgTitle, "green-snackbar");
        if (page) {
          this.router.navigate([page]);
        }
      }
    })
  }
}