import { HttpHeaders } from "@angular/common/http";

export class Constant {
    public static readonly APPLICATION_NAME = "PRESTO";
    public static readonly AUTH_ERROR_REDIRECT = "error/access-denied";

    public static readonly headers = new HttpHeaders({ "Content-Type": "application/json" });
    public static readonly PERM_READ = "PRESTO_PERM_READ";
    public static readonly PERM_ADMIN = "PRESTO_PERM_ADMIN";
    public static readonly CREATE_REVISION="1";
    public static readonly VIEW_ALL="2";
    public static readonly VIEW_ALTERNATE="3";
    public static readonly VIEW_PRIOR_LATEST="4";
    public static readonly VIEW_CURRENT="5";

    public static readonly SELECT_TYPE_SINGLE="single-select";
    public static readonly SELECT_TYPE_MULTI="multi-select";

    public static readonly SIZE_VALVE_TAB="Size Valve";

    //MRO Permissions
    public static readonly PERMISSION_MWS_AS_SHIPPED_BOM = "MWS_AS_SHIPPED_BOM";
    public static readonly PERMISSION_INTERNAL_USER = "MWS_AS_SHIPPED_BOM1";
    public static readonly PERMISSION_LBP_USER = "LBP_USER";
   
    public static readonly PERMISSION_ACCESS_PWS = "ACCESS_PWS";
    public static readonly PERMISSION_ACCESS_MWS = "ACCESS_MWS";
  }
