import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Config } from '../../../../../../shared/config';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { ValveSizingResponse } from 'src/app/pages/tag-workspace/store/models/sizevalve/valve.sizing.response';
import { ValveSizingRequest } from 'src/app/pages/tag-workspace/store/models/sizevalve/valve.sizing.request';
import { RequestCalculateData } from 'src/app/pages/tag-workspace/store/models/sizevalve/request.calculate.data';
import { CalculateResult } from 'src/app/pages/tag-workspace/store/models/sizevalve/calculate.result';
import { CommonApiService } from '../../../../../../shared/services/common-api.service';
import { InputSizingChangeUOM } from 'src/app/pages/tag-workspace/store/models/sizevalve/input.sizing.changeuom';

@Injectable({
  providedIn: 'root',
})
export class SizingDataService {
  private config: Config;

  constructor(private apiService: CommonApiService) {
    this.config = new Config();
  }

  fetchValveSizingData(request: ValveSizingRequest): Observable<ValveSizingResponse> {
    const url = `${this.config.GET_VALVESIZINGDATA_API}`;

    const response = this.apiService
      .post<ValveSizingResponse>(url, request, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }).pipe(tap({ error: (error) => console.log(error) }));
    return response;
  }

  transformedSizingVariables(request: RequestCalculateData): Observable<CalculateResult> {
    const url = `${this.config.TRANSFORMED_SIZING_VARIABLES_API}`;

    const response = this.apiService
      .post<CalculateResult>(url, request, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }).pipe(tap({ error: (error) => console.log(error) }));
    return response;
  }

  transformChangeUOM(request: InputSizingChangeUOM): Observable<CalculateResult> {
    const url = `${this.config.TRANSFORM_CHANGEUOM_API}`;

    const response = this.apiService
      .post<CalculateResult>(url, request, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }).pipe(tap({ error: (error) => console.log(error) }));
    return response;
  }

  calculateOutputVariables(request: RequestCalculateData): Observable<CalculateResult> {
    const url = `${this.config.CALCULATE_SIZING_CATALOG_API}`;

    const response = this.apiService
      .post<CalculateResult>(url, request, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }).pipe(tap({ error: (error) => console.log(error) }));
    return response;
  }

  transformedRefreshPairedVariables(request: RequestCalculateData) {
    const url = `${this.config.TRANSFORMED_REFRESHPAIREDVARIABLES_API}`;

    const response = this.apiService
      .post<CalculateResult>(url, request, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(tap({ error: (error) => console.log(error) }));
    return response;
  }
}
