import { isInNotificationPhase } from "@angular/core/primitives/signals";

export class Session {
    sessionId: string;
    cookie: string;
    newSession: boolean;

    InitializeSession() {
        this.sessionId = "";
        this.cookie = "This will be loaded from PRESTO API";
        this.newSession = true;
    }
}