export class ProductTileInfo {
    productLineName: string;
    productDescription: string;
    sizeInfo: string;

    constructor() {
        this.productLineName = "";
        this.productDescription = "";
        this.sizeInfo = "";
    }
}