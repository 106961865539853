import { Component } from '@angular/core';
import { VersionService } from "../../services/version.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  version: any;
constructor(
  private versionService: VersionService
) { 
}
  ngOnInit() {
  this.versionService.getVersion().subscribe((data) => { 
    this.version = data.version;
  });
  }
}
