import { error } from './error-msg';
import { SearchResultSerialCard } from './serial-cards-search';

export class BulkSerialSearchResult {
  validSerialCards: {
    serialCards: SearchResultSerialCard[];
    errors: error[];
    warnings: null;
    info: null;
  };
  invalidSerialCards: InvalidSerialCards;
}
export interface InvalidSerialCards {
  invalidSerialNumbers: string[];
  errors: error[];
  warnings: null;
  info: null;
}
export interface BulkSerialSearchResult {
  validSerialCards: {
    serialCards: SearchResultSerialCard[];
    errors: error[];
    warnings: null;
    info: null;
  };
  invalidSerialCards: InvalidSerialCards;
}
