/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  OnInit,
  DoCheck,
  ChangeDetectorRef,
  AfterViewChecked,
  Inject,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { UtilsService as TagWorkspaceUtilsService } from 'src/app/shared/services/buildassembly/utils.service';
import { SecurityService } from 'projects/presto-security/src/public_api';
import { ConfirmationPopUp, Construction } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { DialogType } from '../../enums/common';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationComponent } from 'src/app/pages/tag-workspace/popups/confirmation/confirmation.component';
import { CallBackAction } from '../../enums/buildassembly';
import { CommonUtilityLibraryService } from '../../library/common-utility-library.service';
import { ISummaryView } from 'src/app/pages/project-workspace/model/project-tree-view.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService],
})
export class HeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  ref: DynamicDialogRef;
  isSave: boolean;
  currentPage: string | null;
  userName: string;
  officeName: string;
  tagData = {} as ISummaryView;
  tagDataSub$: Subscription;
  updateTagRevSub$: Subscription;
  changeUserSub$: Subscription;
  subscriptions$: Subscription[] = [];
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private _tagWorkspaceUtilsService: TagWorkspaceUtilsService,
    public cdRef: ChangeDetectorRef,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private ss: SecurityService,
    private commonLibrary: CommonUtilityLibraryService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = this.router.url;
      }
    });
  }

  ngOnInit() {
    this.onChangeTagData();
    this.onChangeUser();
    this.updateTagRev();
  }

  updateTagRev(){
    this.updateTagRevSub$ = this._tagWorkspaceUtilsService.updateTagRev$.subscribe((res: any) => {
      this.tagData.revisionName = res.revisionName;
      this.tagData.revisionId = res.revisionId;
      this.commonLibrary.setTagData(this.tagData);
    });
    this.subscriptions$.push(this.updateTagRevSub$);
  }

  onChangeTagData(){
    this.tagDataSub$ = this.commonLibrary.onChangeTagData().subscribe((data) => {
      this.tagData = data;
    });
    this.subscriptions$.push(this.tagDataSub$);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  homeNav() {
    this.proceedToNavigate(['project']);
  }

  workspaceNav() {
    this.proceedToNavigate(['workspace']);
  }

  projectInfoNav() {
    this.proceedToNavigate(['settings', 'project-info']);
  }

  uomDefaultsNav() {
    this.proceedToNavigate(['settings', 'uom-defaults']);
  }
  pricingSettingsNav() {
    this.router.navigate(['settings', 'pricing-settings']);
  }
  onChangeUser() {
    this.changeUserSub$ = this.ss.onChangeUser().subscribe((user) => {
      this.userName = user?.displayName;
      const office = user.primaryOffice();
      this.officeName = `${office?.referenceId} ${office?.officeName}`;
    });
    this.subscriptions$.push(this.changeUserSub$);
  }

  async logoutClick() {
    await this.oktaAuth.closeSession();
    await this.oktaAuth.clearStorage();
  }

  proceedToNavigate(routeUrl: string[]) {
    this.router.navigate(routeUrl);
  }

  logoClicked(){
    if(this.currentPage == "/tag-workspace")
    {
      this._tagWorkspaceUtilsService.headerLogoClicked("/");
    } else {
      this.proceedToNavigate(["/"]);
    }
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
  }
}
