import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  shouldShowActionBar = false; // Initially true by default
  hideActionBarRoutes: string[] = ['error', 'project', 'workspace', 'tag-workspace', 'settings','addParts'];
  shouldApplyPadding = true; // Initially true to apply padding

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.route?.root?.firstChild?.snapshot;
        if (
          currentRoute &&
          currentRoute.routeConfig &&
          currentRoute.routeConfig.path
        ) {
          this.shouldShowActionBar = !this.hideActionBarRoutes.includes(
            currentRoute.routeConfig.path
          );
          this.shouldApplyPadding = !this.hideActionBarRoutes.includes(
            currentRoute.routeConfig.path
          );
        }
        else {
          this.shouldShowActionBar = false ;
        }
      });
  }
}
