import { Injectable } from '@angular/core';
import { DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { Attribute, AttributeValue, Assignment, Construction, Component as ConComponent, ConfirmationPopUp, SpecialInfoUI, BreakRule, ConflictGroup, ConflictVariable, ConflictUI, Session, BrokenLink, SpecialInfo, ErrorInfo, Conflict } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { ApplyType, AssignedBy, AssignmentState, AttributeTab, AttributeType, BrokenLinkType, ConfigItError, ConfigureAction, ProductType, SelectProductAction } from "src/app/shared/enums/buildassembly";
import { ProductFamilyValidationService } from 'src/app/shared/services/buildassembly/product-family-validation'
import { IProductLine } from "src/app/shared/models/productline-data.model";
import { PricingWorldRegion } from "src/app/shared/enums/common";
import { BuildAssemblyDataService } from '../../data/build-assembly/build-assembly-data.service';
import { BehaviorSubject } from 'rxjs';
interface UpdateConstruction {
    data: Construction | null;
    type: string | null;
  }
@Injectable({
  providedIn: 'root',
})
export class BuildAssemblyService {
  ishasWASConflicts: boolean | undefined;
    isRemoveBreakRules: boolean;
  constructor(
    private _productFamilyValidationService: ProductFamilyValidationService,
    private buildAssemblyDataService: BuildAssemblyDataService
  ) {}

  private constructionDataSubject = new BehaviorSubject<UpdateConstruction | null>({
    data: null,
    type: null,
  });
  constructionData$ = this.constructionDataSubject.asObservable();
  private constructionPayload: Construction;
  configureAction: ConfigureAction;
  userAssignment: Assignment | undefined;
  conflcit: Conflict | undefined;
  isWASAttribute: boolean;

  saveSession(session: Session) {
    if (session?.newSession) {
      //New Session created
      //Remove all the existing assemblies here...
    }
    sessionStorage.setItem('session', JSON.stringify(session));
  }

  removeProduct(
    construction: Construction,
    compToBeRemoved: ConComponent,
    buildRequired: boolean,
    ref: DynamicDialogRef,
    dialogService: DialogService
  ): Construction {
    let newComponents: ConComponent[] = [];
    let mtgParent: ConComponent = new ConComponent();

    construction.confirmationPopUp = new ConfirmationPopUp();
    construction.confirmationPopUp =
      this._productFamilyValidationService.validate(
        construction,
        compToBeRemoved,
        SelectProductAction.Remove
      );
    if (construction.confirmationPopUp.message !== '') {
      return construction;
    }

    newComponents = construction.components.filter(
      (comp: ConComponent) => comp.guid !== compToBeRemoved.guid
    );
    newComponents = newComponents.filter(
      (comp: ConComponent) => comp.parent?.guid !== compToBeRemoved.guid
    );

    if (compToBeRemoved.mtgProductLineId) {
      //To remove the Mtg Parent
      mtgParent = newComponents.find(
        (comp: ConComponent) =>
          comp.productLineId === compToBeRemoved.mtgProductLineId
      )!;
      newComponents = newComponents.filter(
        (comp: ConComponent) =>
          comp.productLineId !== compToBeRemoved.mtgProductLineId
      );
      //To remove the CLS
      if (mtgParent) {
        newComponents = newComponents.filter(
          (comp: ConComponent) => comp.parent?.guid !== mtgParent.guid
        );
      }
    }

    construction.components = newComponents;
    construction = this._productFamilyValidationService.AssignProductTypeInfo(
      construction,
      compToBeRemoved,
      SelectProductAction.Remove
    );

    if (buildRequired) {
      //construction = this.build(construction);
    }

    return construction;
  }

  getConfigItAttributes(
    component: ConComponent,
    attributes: any[],
    assignments: any[]
  ): Attribute[] {
    let newAttributes: Attribute[] = [];

    attributes?.forEach((attribute: any) => {
      let asn: any = {};
      if (assignments) {
        asn = assignments.find(
          (asn) =>
            asn.attributeId === attribute.attributeId &&
            asn.productGuid == component.guid
        );
        if (asn) {
          attribute.assignedBy = asn.assignedBy;
        }
      }

      newAttributes.push(attribute);
    });

    return newAttributes;
  }

  getSpecialAttributesForConfigIt(
    activeComponentGuid: string,
    assignments: Assignment[],
    specialAttributes: Assignment[],
    configureAction: ConfigureAction,
    conflict: Conflict,
    userAssignment?: Assignment
  ): Assignment[] {
    let newSpecialAttributes: Assignment[] = [];
    let oldSpecialAttributes: Assignment[] = [];

    if (!specialAttributes) {
      specialAttributes = [];
    }

    switch (configureAction) {
      case ConfigureAction.dropDownChange:
        newSpecialAttributes = specialAttributes;
        if (userAssignment) {
          newSpecialAttributes = specialAttributes?.filter(
            (splAttr: Assignment) =>
              splAttr.guid !== activeComponentGuid ||
              (splAttr.guid === activeComponentGuid &&
                splAttr.attributeId !== userAssignment.attributeId)
          )!;
          if (userAssignment.assignedBy === AssignedBy.Special) {
            newSpecialAttributes.push(userAssignment);
          }
        }
        break;
      case ConfigureAction.clearSpecialValue:
        if (userAssignment) {
          newSpecialAttributes = specialAttributes?.filter(
            (splAttr: Assignment) =>
              splAttr.guid !== activeComponentGuid ||
              (splAttr.guid === activeComponentGuid &&
                splAttr.attributeId !== userAssignment.attributeId)
          )!;
        }
        break;
      case ConfigureAction.turnOff:
      case ConfigureAction.turnOff_Prompt:
        newSpecialAttributes = [...specialAttributes, ...newSpecialAttributes];
        if (conflict?.conflictGroups?.length > 0) {
          conflict.conflictGroups.forEach((conflictGroup: ConflictGroup) => {
            let specialAttributesFromConflicts: Assignment[] = [];
            specialAttributesFromConflicts =
              this.getAssignmentFromConflictVaraible(
                conflictGroup,
                assignments,
                conflictGroup.primaryAssignments
              );
            newSpecialAttributes = [
              ...newSpecialAttributes,
              ...specialAttributesFromConflicts,
            ];
            specialAttributesFromConflicts =
              this.getAssignmentFromConflictVaraible(
                conflictGroup,
                assignments,
                conflictGroup.secondaryAssignments
              );
            newSpecialAttributes = [
              ...newSpecialAttributes,
              ...specialAttributesFromConflicts,
            ];
            specialAttributesFromConflicts =
              this.getAssignmentFromConflictVaraible(
                conflictGroup,
                assignments,
                conflictGroup.internalAssignments
              );
            newSpecialAttributes = [
              ...newSpecialAttributes,
              ...specialAttributesFromConflicts,
            ];
          });
        }
        if (userAssignment) {
          newSpecialAttributes.push(userAssignment);
        }
        break;
      case ConfigureAction.ResolveAttributes:
        oldSpecialAttributes = assignments.filter(
          (asn: Assignment) => asn.assignedBy == AssignedBy.Special
        );
        if (conflict?.conflictGroups?.length > 0) {
          conflict.conflictGroups.forEach((conflictGroup: ConflictGroup) => {
            let specialAttributesFromConflicts: Assignment[] = [];
            conflict.conflictGroups.forEach((conflictGroup: ConflictGroup) => {
              newSpecialAttributes =
                this.removeSpecialAttributesIncaseOfConflicts(
                  conflictGroup.id,
                  conflictGroup.primaryAssignments,
                  specialAttributes
                );
              newSpecialAttributes =
                this.removeSpecialAttributesIncaseOfConflicts(
                  conflictGroup.id,
                  conflictGroup.secondaryAssignments,
                  specialAttributes
                );
            });
          });
        }
        newSpecialAttributes = [...oldSpecialAttributes, ...specialAttributes];
        break;
      case ConfigureAction.setAsSpecial:
        oldSpecialAttributes = assignments.filter(
          (asn: Assignment) => asn.assignedBy == AssignedBy.Special
        );
        newSpecialAttributes = [...oldSpecialAttributes, ...specialAttributes];
        break;
      default:
        newSpecialAttributes = specialAttributes;
    }

    return newSpecialAttributes;
  }

  removeSpecialAttributesIncaseOfConflicts(
    compGUID: string,
    conflictAssignments: ConflictVariable[],
    specialAttributes: Assignment[]
  ): Assignment[] {
    if (!specialAttributes) {
      return [];
    }

    let assignment: Assignment = new Assignment();

    conflictAssignments.forEach((conflictAssignment: ConflictVariable) => {
      specialAttributes.splice(
        specialAttributes.findIndex(
          (asn: Assignment) =>
            asn.guid === compGUID &&
            asn.attributeId === conflictAssignment.attributeId
        ),
        1
      );
    });

    return specialAttributes;
  }

  getAssignmentFromConflictVaraible(
    conflictGroup: ConflictGroup,
    conAssignments: Assignment[],
    conflictAssignments: Assignment[]
  ): Assignment[] {
    let assignment: Assignment = new Assignment();
    let specialAttributes: Assignment[] = [];
    conflictAssignments.forEach((conflictAssignment: Assignment) => {
      assignment = conAssignments.find(
        (asn: Assignment) =>
          asn.guid === conflictGroup.id &&
          asn.variableId === conflictAssignment.variableId
      )!;
      if (assignment?.variableId) {
        specialAttributes.push(assignment);
      }
    });

    return specialAttributes;
  }

  ClearSpecialAttributeValue(
    construction: Construction,
    assinment: Assignment
  ): string {
    let comp: ConComponent;
    let attrValueName: string = '';

    comp = construction.components.find(
      (comp: ConComponent) => comp.guid === assinment.guid
    )!;
    if (comp) {
      let attribute: Attribute;
      let attributes: Attribute[];

      attributes = [...comp.primaryVariables, ...comp.secondaryVariables];
      attribute = attributes.find(
        (attrib: Attribute) => attrib.attributeId === assinment.attributeId
      )!;
      if (attribute) {
        attrValueName = attribute.values.find(
          (atv: AttributeValue) => atv.attrValueId === assinment.attrValueId
        )?.name!;
        attribute.value = attrValueName;
        attribute.assignedBy = AssignedBy.User;
        attribute.selectedOption = AttributeTab.Published;
      }
    }

    return attrValueName;
  }

  getAssignmentsForConfigIt(
    construction: Construction,
    configureAction: ConfigureAction,
    userAssignment?: Assignment
  ): Assignment[] {
    let priAssignment: Assignment[] = [];
    let secAssignment: Assignment[] = [];
    let intAssignment: Assignment[] = [];
    let assignments: Assignment[] = [];

    construction.components.forEach((comp: any) => {
      priAssignment = this.getAttributes(
        comp,
        comp.primaryVariables,
        AttributeType.Primary
      );
      secAssignment = this.getAttributes(
        comp,
        comp.secondaryVariables,
        AttributeType.Secondary
      );
      intAssignment = this.getAttributes(
        comp,
        comp.internalVariables,
        AttributeType.internel
      );
      assignments = [
        ...assignments,
        ...priAssignment,
        ...secAssignment,
        ...intAssignment,
      ];
    });

    let variables1: any[] = [];
    let variables2: any[] = [];

    switch (configureAction) {
      case ConfigureAction.updateComponentStructure:
        let componentGIDs: string[];
        componentGIDs = construction.components?.map(
          (comp: ConComponent) => comp.guid!
        );
        assignments = assignments.filter((asn: Assignment) =>
          componentGIDs.includes(asn.guid!)
        );
        assignments = this.sortAssignments(
          construction.components,
          assignments
        );
        break;
      case ConfigureAction.dropDownChange:
        if (userAssignment) {
          let assignments1: any[] = [];
          let assignments2: any[] = [];

          assignments1 = assignments.filter(
            (asn) => asn.guid !== construction.activeComponentGuid
          );
          assignments2 = assignments.filter(
            (asn) =>
              asn.guid === construction.activeComponentGuid &&
              asn.attributeId !== userAssignment?.attributeId
          );
          assignments = [...assignments1, ...assignments2];
          assignments = this.sortAssignments(
            construction.components,
            assignments
          );

          if (userAssignment?.attrValueId) {
            assignments.push(userAssignment);
          }
        }
        break;
      case ConfigureAction.clearSelection:
        if (userAssignment) {
          let assignments1: any[] = [];
          let assignments2: any[] = [];

          assignments1 = assignments.filter(
            (asn) => asn.guid !== construction.activeComponentGuid
          );
          assignments2 = assignments.filter(
            (asn) =>
              asn.guid === construction.activeComponentGuid &&
              asn.attributeId !== userAssignment?.attributeId
          );
          assignments = [...assignments1, ...assignments2];
        }
        assignments = this.sortAssignments(
          construction.components,
          assignments
        );
        break;
      case ConfigureAction.setDefaults:
        break;
      case ConfigureAction.setAllDefaults:
        break;
      case ConfigureAction.clearDefaults:
        variables1 = assignments.filter(
          (asn) => asn.guid !== construction.activeComponentGuid
        );
        variables2 = assignments.filter(
          (asn) =>
            asn.guid === construction.activeComponentGuid &&
            (asn.assignedBy === AssignedBy.User ||
              asn.assignedBy === AssignedBy.Sizing ||
              asn.variableName === 'World Area Selections')
        );
        assignments = [...variables1, ...variables2];
        assignments = this.sortAssignments(
          construction.components,
          assignments
        );
        break;
      case ConfigureAction.clearAllDefaults:
        variables1 = assignments.filter(
          (asn) =>
            asn.assignedBy === AssignedBy.User ||
            asn.assignedBy === AssignedBy.Sizing ||
            asn.variableName === 'World Area Selections'
        );
        assignments = variables1;
        break;
      case ConfigureAction.clear:
        variables1 = assignments.filter(
          (asn) => asn.guid !== construction.activeComponentGuid
        );
        variables2 = assignments.filter(
          (asn) =>
            asn.guid === construction.activeComponentGuid &&
            asn.variableName === 'World Area Selections'
        );
        assignments = [...variables1, ...variables2];
        assignments = this.sortAssignments(
          construction.components,
          assignments
        );
        break;
      case ConfigureAction.clearAll:
        assignments = assignments.filter(
          (asn) => asn.variableName === 'World Area Selections'
        );
        break;
      case ConfigureAction.ResolveAttributes:
        construction.conflict.conflictGroups.forEach(
          (conflictGroup: ConflictGroup) => {
            assignments = this.removeConflictFromAssignments(
              conflictGroup.id,
              conflictGroup.primaryAssignments,
              assignments
            );
            assignments = this.removeConflictFromAssignments(
              conflictGroup.id,
              conflictGroup.secondaryAssignments,
              assignments
            );
            assignments = this.removeConflictFromAssignments(
              conflictGroup.id,
              conflictGroup.internalAssignments,
              assignments
            );
          }
        );
        assignments = this.sortAssignments(
          construction.components,
          assignments
        );
        break;
      case ConfigureAction.setAsSpecial:
        break;
      case ConfigureAction.undoSpecial:
        let activeComponentSpecialAssignments: Assignment[];
        activeComponentSpecialAssignments =
          construction.specialAttributes.filter(
            (asn: Assignment) => asn.guid === construction.activeComponentGuid
          );
        construction.specialAttributes = construction.specialAttributes.filter(
          (asn: Assignment) => asn.guid !== construction.activeComponentGuid
        );

        activeComponentSpecialAssignments.forEach((asn: Assignment) => {
          if (asn.attrValueId) {
            assignments.splice(
              assignments.findIndex(
                (t: Assignment) =>
                  t.guid === asn.guid && t.attributeId === asn.attributeId
              ),
              1
            );
            asn.assignedBy = AssignedBy.User;
            asn.specialValue = '';
            asn.assignmentType = 'Custom';
            assignments.push(asn);
          }
        });
        break;
      case ConfigureAction.clearSpecialValue:
        if (userAssignment) {
          let oldSpecialAssignments: Assignment[];
          oldSpecialAssignments = construction.specialAttributes;

          userAssignment = oldSpecialAssignments.find(
            (asn: Assignment) =>
              asn.guid === construction.activeComponentGuid &&
              asn.attributeId === userAssignment?.attributeId
          );
          if (userAssignment && userAssignment.attrValueId) {
            assignments.splice(
              assignments.findIndex(
                (t: Assignment) =>
                  t.guid === userAssignment?.guid &&
                  t.attributeId === userAssignment?.attributeId
              ),
              1
            );
            userAssignment.assignedBy = AssignedBy.User;
            userAssignment.specialValue = '';
            userAssignment.assignmentType = 'Custom';
            assignments.push(userAssignment);
          }
        }
        assignments = this.sortAssignments(
          construction.components,
          assignments
        );
        break;
      case ConfigureAction.turnOff:
      case ConfigureAction.turnOff_Prompt:
        assignments = construction.assignments;
        break;
    }

    return assignments;
  }

  sortAssignments(
    components: ConComponent[],
    assignments: Assignment[]
  ): Assignment[] {
    let sortedAssignments: Assignment[] = [];
    components.forEach((component: ConComponent) => {
      component.primaryVariables.forEach((attr: Attribute) => {
        let assignment: Assignment = assignments.find(
          (asn: Assignment) =>
            asn.guid === component.guid && asn.attributeId === attr.attributeId
        )!;
        if (assignment) {
          sortedAssignments.push(assignment);
        }
      });

      component.secondaryVariables.forEach((attr: Attribute) => {
        let assignment: Assignment = assignments.find(
          (asn: Assignment) =>
            asn.guid === component.guid && asn.attributeId === attr.attributeId
        )!;
        if (assignment) {
          sortedAssignments.push(assignment);
        }
      });

      component.internalVariables.forEach((attr: Attribute) => {
        let assignment: Assignment = assignments.find(
          (asn: Assignment) =>
            asn.guid === component.guid && asn.attributeId === attr.attributeId
        )!;
        if (assignment) {
          sortedAssignments.push(assignment);
        }
      });
    });
    return sortedAssignments;
  }

  removeConflictFromAssignments(
    compGUID: string,
    assignments: Assignment[],
    completeAssignments: Assignment[]
  ): Assignment[] {
    assignments.forEach((conflictAssignment: Assignment) => {
      let assignmentIndex = completeAssignments.findIndex(
        (asn: Assignment) =>
          asn.guid === compGUID &&
          asn.variableId === conflictAssignment.variableId
      );
      if (assignmentIndex >= 0) {
        completeAssignments.splice(assignmentIndex, 1);
      }
    });

    return completeAssignments;
  }

  removeConflictFromAttributes(
    compGUID: string,
    assignments: Assignment[],
    attributes: Attribute[]
  ): Attribute[] {
    let newAttributes: Attribute[] = [];

    attributes.forEach((variable: Attribute) => {
      if (
        assignments.findIndex(
          (asn) => asn.variableId === variable.id && asn.guid === compGUID
        ) >= 0
      ) {
        newAttributes.push(variable);
      }
    });

    return newAttributes;
  }

  getAttributes(
    comp: ConComponent,
    attributes: Attribute[],
    attributeType: AttributeType
  ): Assignment[] {
    let newAssignments: Assignment[] = [];

    if (attributeType === AttributeType.internel) {
      attributes = attributes.filter(
        (atr: Attribute) => atr.name === 'World Area Selections'
      );
    }

    if (attributes.length) {
      attributes.forEach((atr: Attribute, i: number) => {
        if (atr.attrValueId) {
          let value;
          value = atr.values.find((x: AttributeValue) => x.name === atr.value);
          let assignment: Assignment = new Assignment();
          (assignment.guid = comp.guid),
            (assignment.groupName = comp.productLineName),
            (assignment.variableName = atr.name),
            (assignment.variableId = atr.id),
            (assignment.valueName = atr.value),
            (assignment.valueId = value?.id),
            (assignment.attributeId = atr.attributeId),
            (assignment.attrValueId = value?.attrValueId),
            (assignment.assignedBy = atr.assignedBy),
            (assignment.isBroken = false),
            (assignment.attributeType = attributeType),
            (assignment.assignmentType = 'Custom'),
            newAssignments.push(assignment);
        }
      });
    }
    return newAssignments;
  }

  isPublishedAttributeValue(state: number): boolean {
    let flag: boolean = false;
    switch (state) {
      case AssignmentState.Assignable:
      case AssignmentState.Forceable:
      case AssignmentState.UserAssigned:
      case AssignmentState.SystemAssigned:
      case AssignmentState.CompleteAssigned:
      case AssignmentState.SizingAssigned:
        flag = true;
        break;
    }

    return flag;
  }

  assignAttributeValueSortingIndex(
    attributeValues: AttributeValue[]
  ): AttributeValue[] {
    attributeValues.forEach((val: AttributeValue) => {
      if (val.state === AssignmentState.Forceable) {
        val.sortingField = val.id! + 999999;
      } else {
        val.sortingField = val.id;
      }
    });

    return attributeValues;
  }

  reduceConfigItPayLoad(construction: Construction): Construction {
    construction.components?.forEach((comp: ConComponent) => {
      comp.price = [];
      comp.em = [];
      comp.primaryVariables?.forEach((atr: Attribute) => {
        atr.values = [];
        atr.dropdownValues = [];
      });
      comp.secondaryVariables?.forEach((atr: Attribute) => {
        atr.values = [];
        atr.dropdownValues = [];
      });
      comp.internalVariables?.forEach((atr: Attribute) => {
        atr.values = [];
        atr.dropdownValues = [];
      });
    });

    //Treat the special assignment issue in build assembly component
    if (construction.error?.error !== ConfigItError.SpecialAssignment) {
      construction.error = new ErrorInfo();
    }

    construction.conflict = new Conflict();
    construction.specialInfo = new SpecialInfo();
    if (!construction.specialAttributes) {
      construction.specialAttributes = [];
    }

    return construction;
  }

  getUpdateComponentStructurePayLoad(construction: Construction): Construction {
    let products: IProductLine[] = [];
    construction.components?.forEach((comp: ConComponent) => {
      let product: any = {};
      product.id = comp.productLineId;
      product.name = comp.productLineName;
      product.family = comp.productFamily;
      product.count = 1;
      product.guid = comp.guid;
      if (comp.parent?.guid) {
        product.associateProduct = comp.parent;
      }
      products.push(product);
    });

    let constructionPayload: Construction = JSON.parse(
      JSON.stringify(construction)
    );
    constructionPayload.products = products;
    constructionPayload.assignments = this.getAssignmentsForConfigIt(
      construction,
      ConfigureAction.updateComponentStructure
    );
    constructionPayload.applyType = ApplyType.Load;
    constructionPayload = this.reduceConfigItPayLoad(constructionPayload);
    return constructionPayload;
  }

  getSpecialInfoForUI(construction: Construction): Construction {
    let guid: string;
    let productLineId: number;
    let componentName: string;
    let productLineName: string;
    let isBroken: boolean;

    construction?.components.forEach((compLevel1: ConComponent) => {
      compLevel1.specialInfoUI = new SpecialInfoUI();

      if (
        compLevel1.productType !== ProductType.CLS &&
        compLevel1.productType !== ProductType.CLSValve
      ) {
        construction?.components.forEach((compLevel2: ConComponent) => {
          if (
            compLevel2.productType !== ProductType.CLS &&
            compLevel2.productType !== ProductType.CLSValve
          ) {
            if (compLevel1.guid !== compLevel2.guid) {
              isBroken = this.isBroken(
                construction.breakRules,
                compLevel1.guid!,
                compLevel2.guid!
              );
              guid = compLevel2.guid!;
              productLineId = compLevel2.productLineId!;
              componentName = compLevel2.componentName;
              productLineName = compLevel2.jdePartNumber!;
              if (isBroken) {
                compLevel1.specialInfoUI.hasBrokenLink = true;
                compLevel1.specialInfoUI.turnOnBuildAssemblyRules = false;
              }

              compLevel1.specialInfoUI.AddBrokenComponent(
                guid,
                productLineId,
                componentName,
                productLineName,
                isBroken,
                compLevel2.primaryVariables,
                compLevel2.secondaryVariables
              );
            }
          }
        });
      }
    });

    construction?.components.forEach((comp: ConComponent) => {
      if (comp.guid === construction.activeComponentGuid) {
        comp.specialInfoUI.AssignBrokenInfo(
          comp.primaryVariables,
          comp.secondaryVariables
        );
        if (!this.isBreakRuleExist(construction.breakRules, comp)) {
          this.clearBrokenAttributeFlag(comp);
        }
      }
    });

    return construction;
  }

  clearBrokenAttributeFlag(comp: ConComponent) {
    comp.primaryVariables.forEach((variable: Assignment) => {
      variable.isBroken = false;
    });
    comp.secondaryVariables.forEach((variable: Assignment) => {
      variable.isBroken = false;
    });
  }

  isBreakRuleExist(breakRule: BreakRule[][], component: ConComponent): boolean {
    let isBreakRuleExist: boolean = false;
    breakRule.forEach((breakRule: BreakRule[]) => {
      if (breakRule[0] && breakRule[1]) {
        if (
          breakRule[0].guid === component.guid ||
          breakRule[1].guid === component.guid
        ) {
          isBreakRuleExist = true;
        }
      }
    });

    return isBreakRuleExist;
  }

  getConflictInfoForUI(construction: Construction): Construction {
    let confictUI: ConflictUI = new ConflictUI();

    if (construction.conflict?.conflictGroups) {
      construction.conflict.conflictGroups.forEach(
        (conflictGroup: ConflictGroup) => {
          let assignments: ConflictVariable[] = [];
          assignments = [
            ...conflictGroup.primaryAssignments,
            ...conflictGroup.secondaryAssignments,
            ...conflictGroup.internalAssignments,
          ];
          confictUI.AddComponent(
            conflictGroup.productId,
            conflictGroup.componentName,
            conflictGroup.id,
            conflictGroup.primaryAssignments,
            conflictGroup.secondaryAssignments,
            conflictGroup.internalAssignments
          );
        }
      );
    }

    construction.conflictUI = confictUI;
    return construction;
  }

  setIsConflictWhileResolvingAttributes(
    confictUI: ConflictUI,
    construction: Construction
  ): Construction {
    //To determine the Conflicting Attributes
    construction.components.forEach((comp: ConComponent) => {
      let conflictingAttributeNames: any;
      conflictingAttributeNames = confictUI.components.find(
        (conflictComp) => conflictComp.guid === comp.guid
      )?.completeConflictAttribuetNames;

      if (conflictingAttributeNames) {
        if (conflictingAttributeNames.length > 0) {
          comp.primaryVariables.forEach((attr: Attribute) => {
            if (conflictingAttributeNames.includes(attr.name!)) {
              attr.isConflict = true;
            } else {
              attr.isConflict = false;
            }
          });
          comp.secondaryVariables.forEach((attr: Attribute) => {
            if (conflictingAttributeNames.includes(attr.name!)) {
              attr.isConflict = true;
            } else {
              attr.isConflict = false;
            }
          });
        }
      }
    });

    return construction;
  }

  isBroken(
    breakRules: Array<BreakRule[]>,
    guid1: string,
    guid2: string
  ): boolean {
    let isBroken: boolean = false;

    if (breakRules.length > 0) {
      breakRules.forEach((breakRule: BreakRule[]) => {
        if (breakRule[0] && breakRule[1]) {
          if (
            (breakRule[0].guid! === guid1 && breakRule[1].guid! === guid2) ||
            (breakRule[1].guid! === guid1 && breakRule[0].guid! === guid2)
          ) {
            isBroken = true;
          }
        }
      });
    }

    return isBroken;
  }

  getBreakRulesFromSpecialInfo(
    construction: Construction,
    brokenLinkType: BrokenLinkType,
    removeBreakRules?: boolean
  ): Array<BreakRule[]> {
    let breakRules: Array<BreakRule[]> = [];
    let breakRuleSet: BreakRule[];
    let guid: string;
    let productLineId: number;

    this._productFamilyValidationService.initializeProductLineTreeData();

    if (construction.breakRules[0]?.length > 0) {
      breakRules = construction.breakRules;
    }

    if (removeBreakRules) {
      breakRules = [];
    }

    if (breakRules.length === 1 && breakRules[0].length === 0) {
      breakRules = [];
    }

    if (construction.specialInfo?.brokenLinks) {
      for (
        let breakRuleIndex = 0;
        breakRuleIndex < construction.specialInfo.brokenLinks.length;
        breakRuleIndex++
      ) {
        breakRuleSet = [];
        for (
          let productIdIndex = 0;
          productIdIndex <
          construction.specialInfo.brokenLinks[breakRuleIndex].productIds
            .length;
          productIdIndex++
        ) {
          guid =
            construction.specialInfo.brokenLinks[breakRuleIndex].productIds[
              productIdIndex
            ];
          productLineId =
            this._productFamilyValidationService.inflatedProductLines.find(
              (prod: IProductLine) =>
                prod.productLineName ===
                construction.specialInfo.brokenLinks[breakRuleIndex].products[
                  productIdIndex
                ]
            )?.productLineId!;
          breakRuleSet.push(
            new BreakRule(guid, productLineId.toString(), brokenLinkType)
          );
          // guid = construction.specialInfo.brokenLinks[breakRuleIndex].productIds[1];
          // productLineId = this._productFamilyValidationService.inflatedProductLines.find((prod: IProductLine) => prod.productLineName === construction.specialInfo.brokenLinks[i].products[1])?.productLineId!;
          // breakRuleSet.push(new BreakRule(guid, productLineId.toString(), brokenLinkType));
        }
        breakRules.push(breakRuleSet);
      }
    }

    return breakRules;
  }

  getAttributeConflictMessage(
    breakRules: Array<BreakRule[]>,
    specialInfo: SpecialInfo
  ): string {
    let conflictMessage: string = '';
    let products: string[] = [];
    let breakRulesExist: boolean;

    if (!breakRules) {
      breakRules = [[]];
    }
    if (!specialInfo || !specialInfo.brokenLinks) {
      return '';
    }

    if (specialInfo.brokenLinks) {
      specialInfo.brokenLinks.forEach((brokenLink: BrokenLink) => {
        //To avoid adding Product lines in the message when the Break rules already exists
        breakRulesExist = false;
        breakRules.forEach((breakRule: BreakRule[]) => {
          if (
            (breakRule[0]?.guid === brokenLink.productIds[0] &&
              breakRule[1]?.guid === brokenLink.productIds[1]) ||
            (breakRule[0]?.guid === brokenLink.productIds[1] &&
              breakRule[1]?.guid === brokenLink.productIds[0])
          ) {
            breakRulesExist = true;
          }
        });
        if (!breakRulesExist) {
          products = [...products, ...brokenLink.products];
        }
      });
    }

    products = [...new Set(products)];
    conflictMessage =
      'Assembly compilation failed. ' +
      products.join() +
      ' product(s) is/are in conflict.';

    return conflictMessage;
  }

  isCompilationErrorDueToCLS(
    components: ConComponent[],
    specialInfo: SpecialInfo
  ): boolean {
    let isCLSCompilationError: boolean = false;
    let brokenComponentGUIDs: string[] = [];

    if (!specialInfo || !specialInfo.brokenLinks) {
      return isCLSCompilationError;
    }

    specialInfo.brokenLinks.forEach((brokenLink: BrokenLink) => {
      brokenComponentGUIDs = [
        ...brokenComponentGUIDs,
        ...brokenLink.productIds,
      ];
    });

    brokenComponentGUIDs.forEach((compGUID: string) => {
      let component: ConComponent;
      component = components.find(
        (comp: ConComponent) => comp.guid === compGUID
      )!;
      if (component) {
        if (
          component.productType == ProductType.CLS ||
          component.productType === ProductType.CLSValve
        ) {
          isCLSCompilationError = true;
        }
      }
    });

    return isCLSCompilationError;
  }

  removeBreakRules(
    components: ConComponent[],
    breakRules: Array<BreakRule[]>,
    parentGUID?: string
  ): Array<BreakRule[]> {
    let newBreakRules: Array<BreakRule[]> = [];
    let mtgParent: ConComponent;

    breakRules.forEach((breakRule: BreakRule[]) => {
      if (breakRule[0] && breakRule[1]) {
        if (
          components.find(
            (comp: ConComponent) => comp.guid === breakRule[0].guid
          ) &&
          components.find(
            (comp: ConComponent) => comp.guid === breakRule[1].guid
          )
        ) {
          newBreakRules.push(breakRule);
        }
      }
    });

    return newBreakRules;
  }

  hasWASConflicts(construction: Construction): boolean {
    let hasWASConflicts: boolean = false;

    construction.conflict.conflictGroups.forEach(
      (conflictGroup: ConflictGroup) => {
        if (
          conflictGroup.internalAssignments.find(
            (conflictVariable: ConflictVariable) =>
              conflictVariable.variable === 'World Area Selections'
          )
        ) {
          hasWASConflicts = true;
        }
      }
    );

    return hasWASConflicts;
  }

  getWASPopWarningProducts(construction: Construction): string[] {
    let wasPopWarningProducts: string[] = [];

    if (construction.pricingHeader.region == PricingWorldRegion.NA) {
      construction.components.forEach((component: ConComponent) => {
        if (component.internalVariables) {
          let wasAttribute: Attribute;
          wasAttribute = component.internalVariables.find(
            (asn: Attribute) => asn.attributeId === 4397
          )!;
          if (wasAttribute) {
            if (wasAttribute.value !== 'North America') {
              wasPopWarningProducts.push(
                component.componentName +
                  ' ' +
                  component.productLineName +
                  ' (' +
                  wasAttribute.value +
                  ')'
              );
            }
          }
        }
      });
    }
    return wasPopWarningProducts;
  }

  updateComponentStucure(constructionPayload: Construction, type: string) {
    this.buildAssemblyDataService
      .updateComponentStucure(constructionPayload)
      .subscribe({
        next: (result: any) => {
          const constructionResult = result as Construction;
          if (constructionResult) {
            console.log('updateComponentStucure BAS', constructionResult, type);
            this.constructionDataSubject.next({
              data: constructionResult,
              type,
            });
          }
        },
      });
  }

  complete(constructionPayload: Construction, type: string) {
    this.buildAssemblyDataService.complete(constructionPayload).subscribe({
      next: (result: any) => {
        const constructionResult = result as Construction;
        if (constructionResult) {
          console.log('Complete BAS', constructionResult, type);
          this.constructionDataSubject.next({ data: constructionResult, type });
        }
      },
    });
  }

  specials(constructionPayload: Construction, type: string) {
    this.buildAssemblyDataService.sepcials(constructionPayload).subscribe({
      next: (result: any) => {
        const constructionResult = result as Construction;
        if (constructionResult) {
          console.log('Specials BAS', constructionResult, type);
          this.constructionDataSubject.next({ data: constructionResult, type });
        }
      },
    });
  }
  configure(constructionPayload: Construction, type: string) {
    this.buildAssemblyDataService.configure(constructionPayload).subscribe({
      next: (result: any) => {
        const constructionResult = result as Construction;
        if (constructionResult) {
          console.log('Configure BAS', constructionResult, type);
          this.constructionDataSubject.next({ data: constructionResult, type });
        }
      },
    });
  }

  setConstrctionPayloadData(constructionPayload: Construction): void {
    this.constructionPayload = constructionPayload;
  }

  getConstructionPayloadData(): Construction {
    return this.constructionPayload;
  }

  setConfigureAction(val: ConfigureAction) {
    this.configureAction = val;
  }

  getConfigureAction(): ConfigureAction {
    return this.configureAction;
  }

  setUserAssignment(val: Assignment | undefined) {
    this.userAssignment = val;
  }

  getUserAssignment(): Assignment | undefined {
    return this.userAssignment;
  }
  setIsWASAttribute(val: boolean) {
    this.isWASAttribute = val;
  }
  getIsWASAttribute(): boolean {
    return this.isWASAttribute;
  }
  setConflict(conflict: Conflict | undefined) {
    this.conflcit = conflict;
  }

  getConflict(): Conflict | undefined {
    return this.conflcit;
  }
  setHasWASConflicts(hasWASConflicts: boolean | undefined) {
    this.ishasWASConflicts = hasWASConflicts;
  }

  getHasWASConflicts(): boolean | undefined {
    return this.ishasWASConflicts;
  }

  setRemoveBreakRules(val: boolean): void {
    this.isRemoveBreakRules= val;
  }

  getRemoveBreakRules(): boolean {
    return this.isRemoveBreakRules;
  }

  clearBuildAssemblyState() {
    this.constructionDataSubject.next(null);
    this.setConfigureAction(ConfigureAction.none);
    this.setUserAssignment(undefined);
    this.setIsWASAttribute(false);
    this.setConflict(undefined);
    this.setHasWASConflicts(undefined);
    this.setRemoveBreakRules(false);
  }
}