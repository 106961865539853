import { DesignAttribute } from './design.attribute';
import { ConditionName } from './condition.name';
import { SizingMethod } from './sizing.method';
import { VarList } from './var.list';
import { InputSizingChangeUOM } from './input.sizing.changeuom';

export class InputSizingChangeUOMClass implements InputSizingChangeUOM {
    designVariables: DesignAttribute[] | null;
    conditionNames: ConditionName[] | null;
    sizingMethod: SizingMethod | null;
    varList: VarList[] | null;

    constructor(
        designVariables: DesignAttribute[] | null,
        conditionNames: ConditionName[] | null,
        sizingMethod: SizingMethod | null,
        varList: VarList[] | null,
    ) {
        this.designVariables = designVariables;
        this.conditionNames = conditionNames;
        this.sizingMethod = sizingMethod;
        this.varList = varList;
    }
    VarList: VarList[] | null;
}