import { BrokenAttribute, BrokenLink } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';

export class SpecialInfo{
    brokenLinks: BrokenLink[];
    brokenAttributes: BrokenAttribute[];
    activeAttributes: BrokenAttribute[];

    constructor(){
        this.brokenLinks = [];
        this.brokenAttributes = [];
        this.activeAttributes = [];
    }
}