export enum ItemTypes {
    SerialNo = "1",
    TagNo = "2",
    RepOrderNo = "3",
    Project = "4",
    CustomerPO = "5",
    LBPSO = '6',
    FSOptions = "7",
    EMString = "8",
    EMOECSP = "9",
    Product = "10",
    OracleOrderNo = "11",
    CustomerNo = "12",
    CustomerName = "13",
    SiteNo = "14",
    SiteName = "15",
}
export enum  ProductStructureType{
    FS_Options = 1,
    EM_String = 2,
    EM_OE_CSP = 3,
    Special_Part = 4,
    Product = 5
}

export const ItemTypesArray = [
    {id: ItemTypes.SerialNo, label: 'Serial #'},
    {id: ItemTypes.RepOrderNo, label: 'Rep Order #'},
    {id: ItemTypes.CustomerPO, label: 'Customer PO'},
    {id: ItemTypes.TagNo, label: 'Tag #'},
    {id: ItemTypes.Project, label: 'Project #'},
    {id: ItemTypes.CustomerName, label: 'Customer Name'},
    {id: ItemTypes.CustomerNo, label: 'Customer #'},
    {id: ItemTypes.SiteNo, label: 'Site #'},
    {id: ItemTypes.OracleOrderNo, label: 'Oracle Order #'},
    {id: ItemTypes.SiteName, label: 'Site Name'},
    {id: ItemTypes.LBPSO, label: 'LBPSO'},
    {id: ItemTypes.FSOptions, label: 'FS Options'},
    {id: ItemTypes.EMString, label: 'EM String'},
    {id: ItemTypes.EMOECSP, label: 'EM OE CSP'},
    {id: ItemTypes.Product, label: 'Product'},
];

export const SerialType = {
    SerialNo: "Serial No",
    TagNo: "Tag",
    RepOrderNo: "Rep Order",
    Project: "Project",
    CustomerPO: "Customer PO",
    OracleOrderNo: "Oracle Order No",
    CustomerNo: "Customer No",
    CustomerName: "Customer Name",
    SiteNo: "Site No",
    SiteName: "Site Name",
}