import { Component } from '@angular/core';

@Component({
  selector: 'app-browser-navigation-popup',
  templateUrl: './browser-navigation-popup.component.html',
  styleUrls: ['./browser-navigation-popup.component.scss']
})
export class BrowserNavigationPopupComponent {

}
