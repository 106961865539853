import { environment } from '../../environments/environment';

export default {
  oidc: {
    clientId: environment.CLIENT_ID,
    issuer: environment.ISSUER,
    redirectUri: environment.oktaRedirectUri,
    scopes: ['openid', 'profile', 'email']
  }
};


export class Config {
  public SHOW_TAG_LIMIT = 10;
  public CONTENT_TYPE = { 'content-type': 'application/json', "version": "1" }

  private PROJECT_PATH = '/api/v1/Project/';
  public GET_PROJECTS_API = environment.prestoApiUrl + this.PROJECT_PATH + 'GetProjects';
  public POST_PROJECT_API = environment.prestoApiUrl + this.PROJECT_PATH + 'CreateProject';
  public GET_KOB_LIST_API = environment.prestoApiUrl + this.PROJECT_PATH + 'GetKOB';
  public GET_PROJECT_STATUS_LIST_API = environment.prestoApiUrl + this.PROJECT_PATH + 'GetProjectStatus';
  public POST_COPY_PROJECT_API = environment.prestoApiUrl + this.PROJECT_PATH + 'CopyProjectUsingDapper';
  public DELETE_PROJECT_API = environment.prestoApiUrl + this.PROJECT_PATH + 'DeleteProjectUsingDapper';
  public GET_REVISION_NOTATIONS_API = environment.prestoApiUrl + this.PROJECT_PATH + 'GetRevisionNotations';
  public GET_PROJECT_HEADER_API = environment.prestoApiUrl + this.PROJECT_PATH + 'GetProjectHeader';
  public SAVE_PROJECT_HEADER_API = environment.prestoApiUrl + this.PROJECT_PATH + 'SaveProjectHeader';

  private OFFICE_PATH = '/api/v1/Office/';
  public GET_OFFICE_LIST_API = environment.prestoApiUrl + this.OFFICE_PATH;
  public GET_ALL_OFFICE_LIST_API = environment.prestoApiUrl + this.OFFICE_PATH + 'GetAllOffices';
  public GET_CURRENCY_LIST_API = environment.prestoApiUrl + this.OFFICE_PATH + 'GetCurrencyList';

  private PROJECT_WORKSPACE_PATH = '/api/v1/ProjectWorkSpace/';
  public GET_PROJECT_WORKSPACE_API = environment.prestoApiUrl + this.PROJECT_WORKSPACE_PATH + 'GetProjectTreeViewSummary'

  private PROJECT_CREATEREVISIONS = '/api/v1/TagRevision/';
  public POST_PROJECT_CREATEREVISIONS_API = environment.prestoApiUrl + this.PROJECT_CREATEREVISIONS + 'CreateTagRevisions';
  public PASTE_PROJECT_PASTETEREVISIONS_API = environment.prestoApiUrl + this.PROJECT_CREATEREVISIONS + 'PasteTagRevision';
  public DELETE_PROJECT_DELETEREVISIONS_API = environment.prestoApiUrl + this.PROJECT_CREATEREVISIONS + 'DeleteTagRevision';

  private PROJECT_RENAME_GROUP = '/api/v1/Group/';
  public PUT_PROJECT_RENAME_GROUP_API = environment.prestoApiUrl + this.PROJECT_RENAME_GROUP + 'RenameGroup';
  public POST_PROJECT_CREATE_NAME_GROUP_API = environment.prestoApiUrl + this.PROJECT_RENAME_GROUP + 'CreateGroup';
  public DELETE_GROUP_API = environment.prestoApiUrl + this.PROJECT_RENAME_GROUP + 'DeleteGroup';

  private PROJECT_TAG_QTY = '/api/v1/Tag/';
  public PUT_PROJECT_TAG_QTY_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'UpdateTagQuantity';
  public PUT_PROJECT_TAG_UPDATE_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'UpdateTagName';
  public PUT_PROJECT_TAG_UPDATE_HASHTAG_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'UpdateHashTag';
  public POST_CREATE_TAG_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'CreateTag';
  public PUT_RENAME_TAG_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'RenameTag';
  public DELETE_TAG_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'DeleteTag';
  public DUPLICATE_TAG_API = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'PasteTag';
  public PUT_UPDATE_TAG_GROUP = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'UpdateTagGroup';
  public UPDATE_ITEM_NUMBER = environment.prestoApiUrl + this.PROJECT_TAG_QTY + 'UpdateItemNumber';

  private PROJECT_CONSTRUCTION = '/api/v1/Construction/';
  public PUT_PASTE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'PasteConstruction';
  public PUT_UPDATE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'UpdateConstructionDescription';
  public DELETE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'DeleteConstruction';
  public SET_AS_PRIMARY_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'SetAsPrimary';
  public CREATE_ALTERNATE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'CreateAlternate';

  private PROJECT_UOMDEFAULT = '/api/v1/UOMDefault/';
  public GET_UOMDEFAULTS_API = environment.prestoApiUrl + this.PROJECT_UOMDEFAULT + 'GetUOMDefaults';
  public POST_UOMDEFAULTS_API = environment.prestoApiUrl + this.PROJECT_UOMDEFAULT + 'SaveUOMDefaults';

  private CONFIG_PATH = "/api/v1/configIt";
  public CONFIGIT_UPDATE_COMPONENT_STRUCTURE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/updateComponentStructure';
  public CONFIGIT_GETSESSION_API = environment.prestoApiUrl + this.CONFIG_PATH + "/getSession";
  public CONFIGIT_REMOVESESSION_API = environment.prestoApiUrl + this.CONFIG_PATH + "/removeSession";
  public CONFIGIT_CONFIGURE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/configure';
  public CONFIGIT_COMPLETE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/complete';
  public CONFIGIT_REPRICE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/reprice';
  public CONFIGIT_SPECIALS_API = environment.prestoApiUrl + this.CONFIG_PATH + '/specials';

  public GET_PROJECTTAG_API = environment.prestoApiUrl + this.PROJECT_WORKSPACE_PATH + 'GetProductTree';

  private PROJECT_BUILDASSEMBLY_PATH = "/api/v1/BuildAssembly/";
  public POST_SAVE_BUILDASSEMBLY_API = environment.prestoApiUrl + this.PROJECT_BUILDASSEMBLY_PATH + 'SaveBuildAssembly';
  public POST_FETCH_BUILDASSEMBLY_API = environment.prestoApiUrl + this.PROJECT_BUILDASSEMBLY_PATH + 'FetchBuildAssembly';

  private PROJECT_SIZING_PATH = "/api/v1/Sizing/";
  public GET_SIZINGMETHODS_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetSizingMethods';
  public GET_SIZINGOPTIONS_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetSizingOptions';
  public GET_SIZINGMETHODSWITHOPTIONS_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetSizingMethodsWithOptions';
  public GET_VALVESIZINGDATA_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetValveSizingData';
  public TRANSFORMED_SIZING_VARIABLES_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'TransformedSizingVariables';
  public CALCULATE_SIZING_CATALOG_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'CalculateSizingCatalog';
  public TRANSFORM_CHANGEUOM_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'TransformChangeUOM';
  public TRANSFORMED_REFRESHPAIREDVARIABLES_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'TransformRefreshPairedVariables';

  private USER_PATH = "/api/v1/User/";
  public GET_USERINFO_API = environment.prestoApiUrl + this.USER_PATH + "getAuthorizedUserInfo";

  private PRICE_PATH = "/api/v1/Price/";
  public PRICE_PROJECT_REPRICE_API = environment.prestoApiUrl + this.PRICE_PATH + 'ProjectReprice';
  public PRICE_GETCURRENCY_CONVERSION_FACTOR_API = environment.prestoApiUrl + this.PRICE_PATH + 'GetCurrencyConversionFactor';
  public PRICE_REPRICE_API = environment.prestoApiUrl + this.PRICE_PATH + 'Reprice';
  public PRICE_GET_PRICING_HEADER_API = environment.prestoApiUrl + this.PRICE_PATH + 'GetPricingHeader/';
  public PRICE_SAVE_PRICE_HEADER = environment.prestoApiUrl + this.PRICE_PATH + 'SavePriceHeader';

  private LOCK_PATH = "/api/v1/Lock/";
  public LOCK_PROJECT = environment.prestoApiUrl + this.LOCK_PATH + 'LockProject';
  public UNLOCK_PROJECT = environment.prestoApiUrl + this.LOCK_PATH + 'UnLockProject';

  //MRO
  private MRO_PATH = "/api/v1/MRO/";
  public GET_SERIAL_CARD_API = environment.prestoApiUrl + this.MRO_PATH + 'SerialSearch';
  public GET_CONSTRUCTION_DETAILS_API = environment.prestoApiUrl + this.MRO_PATH + 'GetConstructionDetails';
  public GET_RECOMMENDED_SPARES_API = environment.prestoApiUrl + this.MRO_PATH + 'GetRecommendedSparesInfo';
  public GET_EXPORT_ALL_SPARES = environment.prestoApiUrl + this.MRO_PATH + 'SparesExcelExport';
  public GET_BOM_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetBOMInfo';
  public GET_PRODUCT_ATTRIBUTE_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetProductsInfo';
  public GET_RECENT_SEARCH_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetUserActivityLog';
  public GET_BULK_SEARCH_DOWNLOAD_TEMPLATE = environment.prestoApiUrl + this.MRO_PATH +'GetBulkSearchUploadTemplate';
  public POST_BULK_SEARCH_UPLOAD = environment.prestoApiUrl + this.MRO_PATH + 'GetExcelSerialSearch';
  public GET_EXPORT_ATTRIBUTES = environment.prestoApiUrl + this.MRO_PATH + 'ProductsExcelExport';
  public GET_MFG_LOCATIONS = environment.prestoApiUrl + this.MRO_PATH + 'GetOrganizationInfo';
  public GET_NOTE_TYPES = environment.prestoApiUrl + this.MRO_PATH + 'GetNoteTypeInfo';
  public GET_DOCUMENTLIST = environment.prestoApiUrl + this.MRO_PATH + 'GetDocumentList';
  public GET_DOCUMENT_FILE = environment.prestoApiUrl + this.MRO_PATH + 'GetDocumentFile';
  public GET_FLEX_SERIAL_CARD = environment.prestoApiUrl + this.MRO_PATH + 'GetFlexSerialCard';
  public GET_SPIREXCELREPORT = environment.prestoApiUrl + this.MRO_PATH + 'SPIRExcelReport';
  public GET_PRODUCT_COMPARE = environment.prestoApiUrl + this.MRO_PATH + 'ProductsCompare';
  public GET_PN_COMPARE_EXCEL_EXPORT = environment.prestoApiUrl + this.MRO_PATH + 'PNCompareExcelExport';
  public GET_FIELD_CHANGE = environment.prestoApiUrl + this.MRO_PATH + 'GetFieldsChanges';
  public GET_PARTS_DETAILS = environment.prestoApiUrl + this.MRO_PATH + 'GetPartsDetails';
  public GET_BULK_PARTS_DATA = environment.prestoApiUrl + this.MRO_PATH + 'GetExcelPartsData';

  public GET_SEARCH_ADDITIONAL_FIELDS = environment.prestoApiUrl + this.MRO_PATH + 'GetSearchAdditionalFields';

  private PROJECT_CATALOG_PATH = "/api/v1/ValveCatalog/";
  public GET_VALVECATALOGPRODUCTS_API = environment.prestoApiUrl + this.PROJECT_CATALOG_PATH + 'GetValveCatalog';
  public GET_VALVECATALOGBASE_API = environment.prestoApiUrl + this.PROJECT_CATALOG_PATH;
  public GET_VALVECATALOGCOEFFICIENTVALUES_API = environment.prestoApiUrl + this.PROJECT_CATALOG_PATH + 'GetCoefficientValues';
  public GET_VALVECATALOGNOTESVALUES_API = environment.prestoApiUrl + this.PROJECT_CATALOG_PATH;
}