import { createReducer, on } from '@ngrx/store';
import { IGroup, ITreeView } from '../pages/project-workspace/model/project-tree-view.model';
import { getTreeData } from './treedata.actions';

class treeData implements ITreeView {
    projectId: number;
    projectName: string;
    groups: IGroup[];
}

export const initialTreeData = new treeData;

export const treeDataReducer = createReducer(
    initialTreeData,
    on(getTreeData, (state: ITreeView, props) => {
        state = props.treeViewData;
        return state;
    }))