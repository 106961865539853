import { ProductType } from "src/app/shared/enums/buildassembly";

export class ComponentSequence {
    id: number;
    productType: ProductType;

    constructor(id: number, productType: ProductType) {
        this.id = id;
        this.productType = productType;
    }
}