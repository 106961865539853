import { Assignment } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';

export class Restriction {
    fixedSelections: Assignment[];
    restrictConstriants: boolean;
    restrictSelections: boolean;

    constructor() {
        this.fixedSelections = [];
        this.restrictConstriants = false;
        this.restrictSelections = false;
    }
}