import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SecurityService } from "../services/security.service";
import { UserInfo } from "../models/user-info";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(
    private securityService: SecurityService,
    private router: Router,) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const permission = route.data["expectedPermission"];
    const errorRedirectUrl = this.securityService.errorRedirectUrl;
    const subscription = this.securityService.login();
    return subscription.pipe(
      map(user => {
        const canProceed = this.hasAccess(user, permission, route.toString());
        if (!canProceed) {
          this.router.navigate([errorRedirectUrl]);
        }
        return canProceed;
      })
    );
  }

  private hasAccess(
    user: UserInfo,
    permission: string,
    route: string
  ): boolean {
    const hasPermission = user.hasPermission(permission);
    if (!hasPermission) {
      console.log(
        `Illegal access for user : ${user.aliasName}. Needed permission ${permission} to access ${route}.`
      );
    }
    return hasPermission;
  }
}
