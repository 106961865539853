import { BaseVariable, ConflictGroup, Constraint } from "src/app/pages/tag-workspace/store/models/build-assembly/buildassembly";

export class Conflict {
    assignment: BaseVariable;
    conflictGroups: ConflictGroup[];
    conflictConstraints: string[];
    isConstraintConflict: boolean;
    isConfigurationConflict: boolean;
    isCatalogConflict: boolean;
    constraints: Constraint;

    constructor() {
    }
}