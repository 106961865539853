import { ApplicationPermission } from "./application-permission";
import { Module } from "./module";
import { ModuleParams } from "./module-params";
import { Office } from "./office";

export class UserInfo {
  private static Anonymous = "Anonymous";

  userId: number;
  aliasName: string;
  emailId: string;
  firstName: string;
  initials: string;
  lastName: string;
  displayName: string;
  title: string;
  authenticated: boolean;
  permissions: ApplicationPermission[];
  offices: Office[];
  modules: Module[];

  constructor() {
    this.aliasName = UserInfo.Anonymous;
    this.authenticated = false;
    this.userId = 0;
    this.offices = [];
    this.permissions = [];
    this.modules = [];
  }

  public static clone(user: UserInfo): UserInfo {
    let result = new UserInfo();

    result.userId = user.userId;
    result.aliasName = user.aliasName;
    result.emailId = user.emailId;
    result.firstName = user.firstName;
    result.initials = user.initials;
    result.lastName = user.lastName;
    result.displayName = user.displayName;
    result.title = user.title;
    result.permissions = result.permissions.concat(user.permissions);
    result.offices = result.offices.concat(user.offices);
    result.modules = result.modules.concat(user.modules);

    return result;
  }

  public hasPermission(permissionName: string): boolean {
    const term = permissionName == null ? "" : permissionName.toUpperCase();
    return (
      this.permissions.find(
        (p) => p.permissionName.toUpperCase().localeCompare(term) === 0
      ) != null
    );
  }

  public hasModulePermission(
    moduleParams: ModuleParams,
    checkEdit: boolean
  ): boolean {
    const moduleType =
      moduleParams.moduleType == null
        ? ""
        : moduleParams.moduleType.toUpperCase();
    const officeName =
      moduleParams.officeName == null
        ? null
        : moduleParams.officeName.toUpperCase();
    const xRefId = moduleParams.xRefId == null ? null : moduleParams.xRefId;

    const moduleResult =
      this.modules.find(
        (m) =>
          m.moduleType.toUpperCase().localeCompare(moduleType) === 0 &&
          m.xRefId === xRefId &&
          (m.canEdit || !checkEdit)
      ) != null;

    const officeResult = officeName
      ? this.offices.find((o) => o.officeName.toUpperCase() === officeName) !=
        null
      : true;

    return moduleResult && officeResult;
  }

  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  public isAnonymous(): boolean {
    return this.userId === 0;
  }

  public primaryOffice(): Office {
    return this.offices.find(o => o.primaryOffice === true)!;
  }
}
