import { ProductType, ProductFamily } from "src/app/shared/enums/buildassembly";
import { AssociateProduct, Assignment, Attribute, EM, ProductTileInfo, SpecialInfoUI } from "src/app/pages/tag-workspace/store/models/build-assembly/buildassembly";
import { Price } from "src/app/shared/models/price";
import { IProductLine } from "src/app/shared/models/productline-data.model";

export class Component {
    componentId?: string;
    constructionId?: string;
    componentName: string;
    productLineName?: string;
    productFamily?: string;
    priceGroupCode: string;
    productType: ProductType;
    productTypeSequence: number;
    productLineId?: number;
    parentComponentId?: string;
    specialPricingId: string;
    specialProductName: string;
    sequenceNumber?: number;
    isComplete: boolean;
    primeShipNA: string;
    primeShipEurope: string;
    productNumber: string;
    createdBy: string;
    createdDate?: Date;
    lastUpdatedBy?: number;
    lastUpdatedDate?: Date;
    guid?: string;
    parentId?: string;
    isConfigurable?: boolean;
    isRegulator?: boolean;
    obsolete?: boolean;
    mtgProductLineId?: number;
    obsoleteQuoteDate?: string;
    obsoleteOrderDate?: string;
    instruction?: string;
    phaseOut?: boolean;
    jdePartNumber?: string;
    levelId?: number;
    children: IProductLine[];
    parent?: AssociateProduct;
    productFamilyUI: ProductFamily;
    primaryVariables: Attribute[];
    secondaryVariables: Attribute[];
    internalVariables: Attribute[];
    price: Price[];
    em: EM[];
    unitListPrice: string;
    phaseOutNotification: string;

    isSelectedForCLS?: boolean;
    productTileInfo: ProductTileInfo;

    specialInfoUI: SpecialInfoUI;

    hasSpecialConfig: boolean;
    hasSpecialPrice: boolean;

    constructor() {

        this.productFamilyUI = ProductFamily.None;
        this.productType = ProductType.None;
        this.productTypeSequence = 99999;
        this.primeShipNA = "";
        this.primeShipEurope = "";
        this.productNumber = "";
        this.componentName = "";
        this.priceGroupCode = "";
        this.specialPricingId = "";
        this.specialProductName = "";
        this.isComplete = false;
        this.unitListPrice = "0";
        this.children = [];
        this.price = [];
        this.em = [];
        this.productTileInfo = new ProductTileInfo();
        this.phaseOutNotification = "";
        this.isComplete = false;
        this.hasSpecialConfig = false;
        this.hasSpecialPrice = false;

        this.primaryVariables = [];
        this.secondaryVariables = [];
        this.internalVariables = [];
        this.specialInfoUI = new SpecialInfoUI();
    }
}