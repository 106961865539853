<div>
    <label *ngIf="label" [ngClass]="labelClassName">{{ label }}</label>
    <div [ngClass]="'relative' + ' ' + inputWrapperClassName">
        <ng-container *ngIf="hasIcon && leftSrc ">
            <img *ngIf="icon" class="absolute left-2 top-[25%]" [src]="leftSrc" alt="Icon Image"
                (click)="iconClick()" />
        </ng-container>
        <input [type]="inputType"
            [ngClass]="defaultInputClass + ' ' + inputClassName + (hasIcon  && leftSrc? 'pl-10' : '')"
            [placeholder]="placeholder" [(ngModel)]="inputValue" (input)="handleInputAction($event)" (focus)="onFocus()"
            (blur)="onBlur()" />
        <ng-container *ngIf="hasIcon && iconPosition === 'right'">
          <img *ngIf="icon" class="absolute right-2 top-[25%] cursor-pointer" [src]="icon" alt="Icon Image" (click)="iconClick()" />
        </ng-container>
    </div>
</div>
