import { ModuleWithProviders, NgModule } from '@angular/core';
import { SecurityService } from './services/security.service';
import { AuthGuard } from './guards/auth-guard';
import { AuthorizationService } from './services/authorization.service';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { IsAuthenticatedDirective } from './directives/is-authenticated.directive';
import { IsNotAuthenticatedDirective } from './directives/is-not-authenticated.directive';
import { NoPermissionDirective } from './directives/no-permission.directive';
import { HasModuleEditPermissionDirective } from './directives/has-module-edit-permission.directive';
import { HasModuleReadPermissionDirective } from './directives/has-module-read-permission.directive';

@NgModule({
  imports: [],
  declarations: [
    HasPermissionDirective,
    NoPermissionDirective,
    HasModuleEditPermissionDirective,
    HasModuleReadPermissionDirective,
    IsAuthenticatedDirective,
    IsNotAuthenticatedDirective
  ],
  providers: [],
  exports: [
    HasPermissionDirective,
    NoPermissionDirective,
    HasModuleEditPermissionDirective,
    HasModuleReadPermissionDirective,
    IsAuthenticatedDirective,
    IsNotAuthenticatedDirective
  ]
})
export class PRESTOSecurityModule {

  static forRoot(
    authUrl: string,
    authApplication: string,
    errorRedirectUrl: string): ModuleWithProviders<PRESTOSecurityModule> {
    return {
      ngModule: PRESTOSecurityModule,
      providers: [
        SecurityService, 
        AuthorizationService,
        AuthGuard,
        { provide: 'securityServiceUrl', useValue: authUrl },
        { provide: 'securityServiceApplication', useValue: authApplication },
        { provide: 'errorRedirectUrl', useValue: errorRedirectUrl }
      ]
    };
  }
}
