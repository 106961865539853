import { ConditionName } from "./condition.name";
import { DesignAttribute } from "./design.attribute";
import { SaveValveSizingRequest } from "./save.valvesize.request";
import { SizingMethod } from "./sizing.method";
import { VariableData } from "./variable.data";


export class SaveValveSizingRequestClass implements SaveValveSizingRequest {
    conditionNames: ConditionName[];
    designVariables: DesignAttribute[];
    valveSizingMethod: SizingMethod | null;
    serviceDescription: DesignAttribute;
    constructionId: number;
    projectId: number;
    tagId: number;
    variables: VariableData[];

    // constructor(
    //     conditionNames: ConditionName[],
    //     designVariables: DesignAttribute[],
    //     valveSizingMethod: SizingMethod | null,
    //     serviceDescription: DesignAttribute,
    //     constructionId: number,
    //     projectId: number,
    //     tagId: number,
    //     variables: VariableDataDTO[]
    // ) {
    //     this.conditionNames = conditionNames;
    //     this.designVariables = designVariables;
    //     this.valveSizingMethod = valveSizingMethod;
    //     this.serviceDescription = serviceDescription;
    //     this.constructionId = constructionId;
    //     this.projectId = projectId;
    //     this.tagId = tagId;
    //     this.variables = variables;
    // }
}