import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy  {
  @Input() label = '';
  @Input() labelClassName = 'block text-gray-700 text-xs 2xl:text-sm font-bold mb-2';
  @Input() inputType = 'text';
  @Input() defaultInputClass =
    'input text-xs 2xl:text-sm pl-8 py-2 bg-white rounded-lg justify-start items-center gap-2 inline-flex w-full focus:!outline-none focus:!rounded-b-none';
  @Input() inputClassName = '';
  @Input() placeholder = '';
  @Input() inputValue = '';
  @Input() inputWrapperClassName = 'mb-2 last:mb-0';
  @Input() hasIcon = false;
  @Input() icon = '';
  @Output() inputFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() inputBlur: EventEmitter<void> = new EventEmitter<void>();

  @Output() inputAction: EventEmitter<any> = new EventEmitter();
  @Output() iconClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output() messageEvent: EventEmitter<any> = new EventEmitter();

  @Input() iconPosition: 'left' | 'right' = 'right'; // Default to right position
  @Input() leftSrc = ''; // New input for left icon

  handleInputAction(event: any): void {
    this.inputAction.emit(event);
    this.messageEvent.emit(this.inputValue)
  }

  onFocus(): void {
    this.inputFocus.emit();
  }

  onBlur(): void {
    this.inputBlur.emit();
  }

  iconClick(): void {
    this.iconClicked.emit();
  }
  constructor() {
    // this.service.get_reset_data().subscribe((res) => {
    //   if(res) {
    //     this.inputValue=''
    //   }
    // })
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {}
}
