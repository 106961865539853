import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { UserInfo } from "../models//user-info";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  userSubject$: BehaviorSubject<UserInfo>;

  constructor(
    private http: HttpClient,
    @Inject("securityServiceUrl") private authUrl: string,
    @Inject("securityServiceApplication") private authApplication: string,
    @Inject("errorRedirectUrl") public errorRedirectUrl: string
  ) {
    this.userSubject$ = new BehaviorSubject(new UserInfo());
  }

  public login(): Observable<UserInfo>;
  public login(email: string): Observable<UserInfo>;

  public login(email?: string): Observable<UserInfo> {
    if (this.authUrl == null) {
      throw Error(
        "The user service url has not been set. Set the url in the forRoot() method of the PRESTOSecurityModule"
      );
    }

    if (email) {
      sessionStorage.setItem("presto-last-login", email);
    } else {
      email = sessionStorage.getItem("presto-last-login")!;
    }

    if (!email) {
      throw Error(
        "The user has not been set. Be sure you are logged in"
      );
    }

    const completed = new Subject<UserInfo>();

    this.fetchUser(
      `${this.authUrl}?applicationName=${this.authApplication}`
    ).subscribe((result) => {
      const newUser = UserInfo.clone(result);
      newUser.authenticated = true;
      completed.next(newUser);
      completed.complete();
      this.userSubject$.next(newUser);
    }),
      catchError((error: HttpErrorResponse) => {
        completed.error(error);
        completed.complete();
        return of(error);
      });
    return completed;
  }

  public onChangeUser(): Observable<UserInfo> {
    return this.userSubject$.asObservable();
  }

  public IsInitialized(): boolean {
    const email = sessionStorage.getItem("presto-last-login");
    return email !== null;
  }

  private fetchUser(url: string): Observable<UserInfo> {
    let user = this.http.get<UserInfo>(url);
    return user;
  }

}