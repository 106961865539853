import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CalculateResult } from 'src/app/pages/tag-workspace/store/models/sizevalve/calculate.result';
import { RequestCalculateFlowCatalogData } from 'src/app/pages/tag-workspace/store/models/catalog/request.calculate.flowcatalog.data';
import { ConditionName } from 'src/app/pages/tag-workspace/store/models/sizevalve/condition.name';
import { DesignAttribute } from 'src/app/pages/tag-workspace/store/models/sizevalve/design.attribute';
import { InputSizingChangeUOMClass } from 'src/app/pages/tag-workspace/store/models/sizevalve/input.sizing.changeuom.class';
import { RequestCalculateData } from 'src/app/pages/tag-workspace/store/models/sizevalve/request.calculate.data';
import { RequestCalculateDataClass } from 'src/app/pages/tag-workspace/store/models/sizevalve/request.calculate.data.class';
import { SizingMethod } from 'src/app/pages/tag-workspace/store/models/sizevalve/sizing.method';
import { SizingOption } from 'src/app/pages/tag-workspace/store/models/sizevalve/sizing.option';
import { UiVars } from 'src/app/pages/tag-workspace/store/models/sizevalve/uivars';
import { ValveSizingRequest } from 'src/app/pages/tag-workspace/store/models/sizevalve/valve.sizing.request';
import { VarList } from 'src/app/pages/tag-workspace/store/models/sizevalve/var.list';
import { VariableData } from 'src/app/pages/tag-workspace/store/models/sizevalve/variable.data';
import { VariableValue } from 'src/app/pages/tag-workspace/store/models/sizevalve/variable.value';
import { SizingDataService } from 'src/app/pages/tag-workspace/store/services/data/size-valve/sizing-data.service';
import * as XLSX from 'xlsx';
import { ValveSizingResponse } from '../../../models/sizevalve/valve.sizing.response';
import { SaveValveSizingRequestClass } from '../../../models/sizevalve/save.valvesize.request.class';
import { SaveValveSizingRequest } from '../../../models/sizevalve/save.valvesize.request';
@Injectable({
  providedIn: 'root',
})
export class SizeValveService {
  public onSizeValeTabEntered$: EventEmitter<any>;
  public onManualSizeButtonClick$: EventEmitter<any>;

  private _conditionNames = new BehaviorSubject<ConditionName[]>([]);
  public conditionNameData$: Observable<ConditionName[]> =
    this._conditionNames.asObservable();

  private _sizingVariables = new BehaviorSubject<VariableData[]>([]);
  public sizingVariablesData$: Observable<VariableData[]> =
    this._sizingVariables.asObservable();

  private _sizingDesignVariables = new BehaviorSubject<DesignAttribute[]>([]);
    designVariablesData$: Observable<DesignAttribute[]> =
    this._sizingDesignVariables.asObservable();

  private _serviceDescription = new BehaviorSubject<DesignAttribute | null>(
    null
  );
  serviceDescriptionData$: Observable<DesignAttribute | null> =
    this._serviceDescription.asObservable();

  private _sizingMethod = new BehaviorSubject<SizingMethod[]>([]);
   sizingOptionsData$: Observable<SizingMethod[]> =
    this._sizingMethod.asObservable();

  private _uomVar = new BehaviorSubject<CalculateResult | null>(null);
   uomVarData$: Observable<CalculateResult | null> =
    this._uomVar.asObservable();

  private _valveSizingResponse = new BehaviorSubject<ValveSizingResponse | null>(
    null
  );
  valveSizingResponseData$: Observable<ValveSizingResponse | null> =
    this._valveSizingResponse.asObservable();

  private calculateButtonSource = new Subject<void | null>();
  calculateButtonClicked = this.calculateButtonSource.asObservable();

  private switchNameOutputVar = new BehaviorSubject<boolean | null>(true);
  switchNameOutput = this.switchNameOutputVar.asObservable();

  private switchNameInputVar = new BehaviorSubject<boolean |null>(true);
  switchNameInput = this.switchNameInputVar.asObservable();

  private exportCsvTrigger = new Subject<void>();
  private exportXlsxTrigger = new Subject<void>();

  exportCsvTriggered = this.exportCsvTrigger.asObservable();
  exportXlsxTriggered = this.exportXlsxTrigger.asObservable();

  private tabClickSource = new Subject<boolean>();
  tabClicked = this.tabClickSource.asObservable();

  private saveConfirm = new Subject<boolean | null>();
  saveConfirmUpdate = this.saveConfirm.asObservable();

  private calculateOutputSubject = new BehaviorSubject<CalculateResult | null>(
    null
  );
  calculateOutput$ = this.calculateOutputSubject.asObservable();

  private changeUOMVarSubject = new BehaviorSubject<any>(null);
  UOMOutputVarData$ = this.changeUOMVarSubject.asObservable();

  private inputData: any[] = [];
  private outputData: any[] = [];
  private isInputDataSet = false;
  private isOutputDataSet = false;
  private _shouldShowOutputGrid: boolean = false;
  private _sizingOptions: SizingOption[] = [];
  isSizeValveAndPCModified: boolean = false;
  _isSizingOptionsModified: boolean = false;
  public _selectedSizingMethod: SizingMethod;
  public _designVariables: DesignAttribute[] = [];
  private columnWidths: { colId: string; width: number }[] = [];


  // Hold the state here for all sizeValve data - use it whenever needed, such as when calling the API.
  // Make sure to keep updating it, whether from the API or a component.
  get sizingVariables(): VariableData[] {
    return this._sizingVariables.getValue();
  }

  set sizingVariables(value: VariableData[]) {
    this._sizingVariables.next(value);
  }

  get conditionNames(): ConditionName[] {
    return this._conditionNames.getValue();
  }

  set conditionNames(value: ConditionName[]) {
    this._conditionNames.next(value);
  }

  get sizingDesignVariables(): DesignAttribute[] {
    return this._sizingDesignVariables.getValue();
  }

  set sizingDesignVariables(value: DesignAttribute[]) {
    this._sizingDesignVariables.next(value);
  }

  get serviceDescription(): DesignAttribute | null {
    return this._serviceDescription.getValue();
  }

  set serviceDescription(value: DesignAttribute | null) {
    this._serviceDescription.next(value);
  }

  get sizingMethod(): SizingMethod[] {
    return this._sizingMethod.getValue();
  }

  set sizingMethod(value: SizingMethod[]) {
    this._sizingMethod.next(value);
  }
  get UOMVar(): CalculateResult | null {
    return this._uomVar.getValue();
  }

  set UOMVar(value: CalculateResult | null) {
    this._uomVar.next(value);
  }


  get shouldShowOutputGrid(): boolean {
    return this._shouldShowOutputGrid;
  }

  set shouldShowOutputGrid(value: boolean) {
    this._shouldShowOutputGrid = value;
  }

  get isSizingOptionsModified(): boolean {
    return this._isSizingOptionsModified;
  }

  set isSizingOptionsModified(value: boolean) {
    this._isSizingOptionsModified = value;
  }

  constructor(private sizingDataService: SizingDataService) {
    this.onSizeValeTabEntered$ = new EventEmitter<any>();
    this.onManualSizeButtonClick$ = new EventEmitter<any>();
  }

  set selectedSizingMethod(value: SizingMethod) {
    this._selectedSizingMethod = value;
  }

  get selectedSizingMethod(): SizingMethod {
    return this._selectedSizingMethod;
  }

  set designVariables(value: DesignAttribute[]) {
    this._designVariables = value;
  }

  get designVariables(): DesignAttribute[] {
    return this._designVariables;
  }

  updateSwitchNameforOutput(newValue: boolean | null) {
    this.switchNameOutputVar.next(newValue);
  }
  updateSwitchNameforInput(newValue: boolean | null) {
    this.switchNameInputVar.next(newValue);
  }

  updateSizingValveState(
    conditionNames: ConditionName[],
    sizingVariables: VariableData[],
    designVariables: DesignAttribute[],
    serviceDescription: DesignAttribute,
    valveSizingOptions: SizingMethod[]
  ) {
    this.conditionNames = conditionNames;
    this.sizingVariables = sizingVariables;
    this.sizingDesignVariables = designVariables;
    this.serviceDescription = serviceDescription;
    this.sizingMethod = valveSizingOptions;
    this.shouldShowOutputGrid = this.setShowOutputGridValue(sizingVariables);
  }

  setShowOutputGridValue(value: VariableData[]): boolean {
    let showGrid = false;
    value.forEach((sizingVariable) => {
      // Use the parameter directly
      if (!sizingVariable.inputVariable) {
        sizingVariable.conditionValues.forEach((conditionValue) => {
          if (
            !showGrid &&
            conditionValue.stringValue !== '' &&
            conditionValue.stringValue !== null
          ) {
            showGrid = true;
          }
        });
      }
    });
    return showGrid;
  }

  transformRequestCalculateData(
    sizingVariables: VariableData[],
    designVariables: DesignAttribute[],
    valveSizingRequest: ValveSizingRequest,
    conditionNames: ConditionName[],
    selectedSizingMethod: SizingMethod | undefined,
    catalogHit: RequestCalculateFlowCatalogData | null = null,
    uiVars: UiVars | null = null,
    serviceDescription: DesignAttribute | null = null
  ): RequestCalculateDataClass {
    return new RequestCalculateDataClass(
      sizingVariables,
      designVariables,
      valveSizingRequest.projectId,
      conditionNames,
      selectedSizingMethod ?? null,
      catalogHit,
      uiVars!,
      serviceDescription
    );
  }

  transformInputSizingChangeUOM(
    event: VarList[],
    designVariables: DesignAttribute[],
    conditionNames: ConditionName[],
    selectedSizingMethod: SizingMethod | undefined
  ): InputSizingChangeUOMClass {

    return new InputSizingChangeUOMClass(
      designVariables,
      conditionNames,
      selectedSizingMethod ?? null,
      event
    );
  }

    updateDesignConditionValues(
      params: VariableData,
      designVariables: DesignAttribute[]
    ) {
      if (params?.conditionValues && params?.designVariables) {
        const maxConditionValue = params.conditionValues.reduce((max: VariableValue | null, conditionValue: VariableValue) => {
          const number = Number(conditionValue.doubleValue);
          return (!isNaN(number) && (max === null || number > Number(max?.doubleValue))) ? conditionValue : max;
        }, null);
    
        if (maxConditionValue) {
          const value: DesignAttribute[] = JSON.parse(JSON.stringify(designVariables));
          value.forEach(designVariable => {
            if (!designVariable.isOverridden && params.designVariables.includes(designVariable.attributeName)) {
              designVariable.displayValue = maxConditionValue.stringValue ?? '';
              designVariable.value = maxConditionValue.doubleValue;
            }
          });
          designVariables = value;
        }
      }
    }
  
    designConditionOverride(
      data: { newValue: string, attributeName: string },
      designVariables: DesignAttribute[]
    ) {
      const { newValue, attributeName } = data;
      const variable = designVariables.find(v => v.attributeName === attributeName);
      if (variable) {
        // Update variable.displayValue with the new value
        variable.displayValue = newValue;
        variable.value = Number(newValue);
        // If the new value is not empty or null, set variable.isOverridden to true
        // Otherwise, set it to false
        variable.isOverridden = newValue !== '' && newValue !== null;
      }
    }  

  calculateButtonClick() {
    this.calculateButtonSource.next();
  }

  triggerCsvExport() {
    this.exportCsvTrigger.next();
  }

  triggerXlsxExport() {
    this.exportXlsxTrigger.next();
  }

  pushInputData(data: any[]) {
    this.inputData = data;
    this.isInputDataSet = true;
    this.checkAndExportData();
  }

  pushOutputData(data: any[]) {
    this.outputData = data;
    this.isOutputDataSet = true;
    this.checkAndExportData();
  }
  private checkAndExportData() {
    if (this.isInputDataSet && this.isOutputDataSet) {
      this.exportDataAsExcel();
      // Reset flags to allow for future exports
      this.isInputDataSet = false;
      this.isOutputDataSet = false;
    }
    else if(this.isInputDataSet && !this.shouldShowOutputGrid){
      this.isInputDataSet = false;
      let csvContent= '';
      if(this.inputData && this.inputData.length >  0) {
      csvContent = 'Sizing Inputs\n\n';
      csvContent += this.convertToCSV(this.inputData);
      }
      this.downloadCSV(csvContent, 'Export.csv');
    }
  }

  pushInputDataCsv(data: any[]) {
    this.inputData = data;
    this.isInputDataSet = true;
    this.checkAndExportDataCsv();
  }

  pushOutputDataCsv(data: any[]) {
    this.outputData = data;
    this.isOutputDataSet = true;
    this.checkAndExportDataCsv();
  }

  private checkAndExportDataCsv() {
      if (this.isInputDataSet && this.isOutputDataSet) {
        this.exportDataAsCSV();
        this.isInputDataSet = false;
        this.isOutputDataSet = false;
      }
      else if(this.isInputDataSet && !this.shouldShowOutputGrid){
        this.isInputDataSet = false;
        let csvContent= '';
        if(this.inputData && this.inputData.length >  0) {
        csvContent = 'Sizing Inputs\n\n';
        csvContent += this.convertToCSV(this.inputData);
        }
        this.downloadCSV(csvContent, 'Export.csv');
      }
  }
  
  exportDataAsCSV() {
    let csvContent= '';
    if(this.inputData && this.inputData.length >  0) {
    csvContent = 'Sizing Inputs\n\n';
    csvContent += this.convertToCSV(this.inputData);
    }
    if (this.outputData && this.outputData.length > 0) {
      csvContent += '\n\nSizing Outputs\n\n';
      csvContent += this.convertToCSV(this.outputData);
    }
    this.downloadCSV(csvContent, 'Export.csv');
  }

  private convertToCSV(jsonData: any[]): string {
    if (jsonData.length === 0) {
      return '';
    }
    const headers = Object.keys(jsonData[0]).join(',') + '\n';
    const body = jsonData
      .map((row) => {
        return Object.values(row).join(',');
      })
      .join('\n');
    return headers + body;
  }

  private downloadCSV(csvData: string, filename: string) {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exportDataAsExcel() {
    const workbook = XLSX.utils.book_new();
    const inputHeaders = this.inputData.length > 0 ? [Object.keys(this.inputData[0])] : [[]];
    const outputHeaders = this.outputData.length > 0 ? [Object.keys(this.outputData[0])] : [[]];
  
    const combinedData = [
      ...this.inputData && this.inputData.length > 0 ? [
        [], // Gap before "Input Data" header
        ['Sizing Inputs'],
        ...inputHeaders, // Column headers for input data
        ...this.inputData.map((item) => Object.values(item)),
        [] // Empty row for separation
      ] : [],
      ...this.outputData && this.outputData.length > 0 ? [
        ['Sizing Outputs'], // Header for output data
        ...outputHeaders, // Column headers for output data
        ...this.outputData.map((item) => Object.values(item))
      ] : []
    ];
    const sheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, sheet, 'Export');
    XLSX.writeFile(workbook, 'Export.xlsx');
    this.outputData = [];
  }

  
  get sizingOptions(): SizingOption[]  {
    return this._sizingOptions;
  }

  set sizingOptions(options: SizingOption[]) {
    this._sizingOptions = options;
  }

  navProcessConAndSizeValve(isProcessCondition: boolean) {
    this.tabClickSource.next(isProcessCondition);
  }

  updateSizeValveAndPCModifiedState(newState: boolean) {
    this.isSizeValveAndPCModified = newState;
    this.emitSaveConfirm();
  }

  private emitSaveConfirm() {
    this.saveConfirm.next(this.isSizeValveAndPCModified);
  }

  calculateOutput(request: RequestCalculateData){
    this.sizingDataService.calculateOutputVariables(request)
   .subscribe({
     next: (result) => {
       if (result) {
        this.isSizingOptionsModified = false;
       this.calculateOutputSubject.next(result);
       this.conditionNames = result.conditionNames;
       this.sizingVariables = result.variables;
       this.shouldShowOutputGrid = this.setShowOutputGridValue(result.variables);
       this.sizingDesignVariables = result.designVariables;
       this.serviceDescription = result.serviceDescription;
       }
     },
   });
 }

 sizingOptionChange(request:RequestCalculateData) {
  this.sizingDataService
      .transformedSizingVariables(request)
      .subscribe({
        next: (result) => {
          if (result) {
            this.sizingVariables = result.variables;
            this.shouldShowOutputGrid = this.setShowOutputGridValue(result.variables);
          }
        },
    });
  }

  transformedRefreshPairedVariables(request: RequestCalculateData) {
    this.sizingDataService
    .transformedRefreshPairedVariables(request)
    .subscribe({
      next: (result) => {
        if (result) {
          this.sizingVariables = result.variables;
          this.shouldShowOutputGrid = this.setShowOutputGridValue(result.variables);
        }
      },
    });
  }

  transformChangeUOM(request: InputSizingChangeUOMClass) {
    this.sizingDataService.transformChangeUOM(request).subscribe({
      next: (result) => {
        if (result) {
          this.UOMVar = result;
        this.sizingDesignVariables = result.designVariables;
        }
      }
    });
  }

 updateUOMVarInSizeInput(data: CalculateResult | null) {
   this.changeUOMVarSubject.next(data);
 }

 getValveSizingData(request: ValveSizingRequest): void {
  this.sizingDataService
      .fetchValveSizingData(request)
      .subscribe({
        next: (result) => {
          if (result) {
            this._valveSizingResponse.next(result);
           this.updateSizingValveState(
              result.valveSizingData.conditionNames,
              result.valveSizingData.variables,
              result.valveSizingData.designVariables,
              result.valveSizingData.serviceDescription,
              result.valveSizingData.valveSizingOptions);
            
          }
        },
      });
  }

  getSaveValveSizingRequest(
      constructionId: number,
      projectId: number,
      tagId: number): SaveValveSizingRequest {
    const returnValue = new SaveValveSizingRequestClass();
    returnValue.tagId = tagId;
    returnValue.projectId = projectId;
    returnValue.conditionNames = this.conditionNames;
    returnValue.designVariables = this.designVariables;
    returnValue.valveSizingMethod = this.selectedSizingMethod ?? null;
    returnValue.serviceDescription = this.serviceDescription ?? {} as DesignAttribute;;
    returnValue.variables = this.sizingVariables;
    returnValue.constructionId = constructionId;

    return returnValue;
  }
  
  setColumnWidths(columnWidths: { colId: string; width: number }[]) {
    this.columnWidths = columnWidths;
  }

  getColumnWidths() {
    return this.columnWidths;
  }

  clearSizeValveState() {
    this.isSizeValveAndPCModified = false;
    this.saveConfirm.next(null);
    this.calculateButtonSource.next(null);
    this.conditionNames = [];
    this.designVariables = []
    this.sizingVariables = [];
    this.serviceDescription =null;
    this.sizingMethod = [];
    this.UOMVar = null;
    this.updateUOMVarInSizeInput(null);
    this._isSizingOptionsModified = false;
    this.updateSwitchNameforInput(null);
    this.updateSwitchNameforOutput(null);
  }
}
