import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Inject,
  AfterViewChecked
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, filter, map, Subscription, noop } from 'rxjs';
import { CommonUtilityLibraryService } from './shared/library/common-utility-library.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { SecurityService } from 'projects/presto-security/src/public_api';
import { AuthState, IDToken, OktaAuth } from '@okta/okta-auth-js';
import { Session } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { SessionManageService } from 'src/app/shared/services/buildassembly/session-manage-api.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  appInitialized = false;
  loginSub: Subscription | undefined;
  tokenSub: Subscription | undefined;
  title = 'PRESTOUI';
  public accessToken$!: Observable<IDToken | unknown>;
  isLoading: boolean = false;
  constructor(
    private router: Router,
    public culibrary: CommonUtilityLibraryService,
    public authService: OktaAuthStateService,
    private ss: SecurityService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private cdrrf: ChangeDetectorRef,
    private sessionManageService: SessionManageService,
  ) {
    this.culibrary.isLoadingFlagChanged().subscribe((res: boolean) => {
      this.isLoading = res;
      this.cdrrf.detectChanges();
    });
  }

  ngOnInit() {
    this.appInitialized = true;
    const destinationPage = this.culibrary.getDestinationPage();

    if (destinationPage) {
      this.culibrary.clearDestinationPage();
      this.router.navigate([destinationPage]);
    }

    this.accessToken$ = this.authService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.idToken ?? false)
    );

    this.tokenSub = this.accessToken$.subscribe((data) => {
      if (data) {
        const accessToken = data as IDToken;
        const claims = Object.entries(accessToken.claims).map((entry) => ({
          name: entry[0],
          value: entry[1],
        }));
        const emailClaim = claims.find((i) => i.name === 'email');
        this.ss.login(`${emailClaim?.value ?? ''}`).subscribe(() => { noop });

        //Invoke this only when the user has permission to create Session
        this.getSession();
      }
    });

    window.onbeforeunload = () => this.ngOnDestroy();
  }
  
  ngAfterViewChecked(): void {
    this.cdrrf.detectChanges();
  }
  onBusyStop() {
    this.appInitialized = true;
  }

  onBusyStart() {}

  ngOnDestroy() {
    //This will be invoked when the API changes are ready.
    //this.removeSession();

    this.loginSub?.unsubscribe();
    this.tokenSub?.unsubscribe();
  }

  getSession(){
    let session: Session;
    session = JSON.parse(sessionStorage.getItem("session")!);
    if(!session) {
      session = new Session();
      session.InitializeSession();
    }
    
    if(session.sessionId === ""){
      this.sessionManageService.getSession().subscribe((res: Session)=>{
        session.sessionId = res.sessionId;
        session.cookie = res.cookie;
        session.newSession = res.newSession;
        
        this.sessionManageService.saveSession(session);
      });
    }
  } 

  removeSession(){
    let session: Session;
    session = JSON.parse(sessionStorage.getItem("session")!);
    if(!session) {
      session = new Session();
      session.InitializeSession();
    }

    if(session.sessionId !== ""){
      this.sessionManageService.removeSession(session.sessionId).subscribe(res=>{    
        sessionStorage.removeItem("session");    
      });
    }
  }
}
