
export class AdvancedSearch {
    tagNumber?: SearchNumber[];
    oracleOrderNumber?: SearchNumber[];
    repOrderNumber?: SearchNumber[];
    projectNumber?: SearchNumber[];
    customerPONumber?: SearchNumber[];
    shipStartDate?: null | Date;
    shipEndDate?: null | Date;
    customerName?: serialSearchType;
    customerNumber?: [];
    siteName?: serialSearchType;
    siteNumber?: null;
    manufacturingLocation?: null | string;
    officeNumber: null | string;
    productStructure?: serialSearchType;
    notes?: serialSearchType;
    constructor() {
        this.shipStartDate = null;
        this.shipEndDate = null;
        this.officeNumber = null;
        this.manufacturingLocation = null;
        this.tagNumber = [];
        this.oracleOrderNumber = [];
        this.repOrderNumber = [];
        this.projectNumber = [];
        this.customerPONumber = [];
        this.customerName = { type: 0, value: null };
        this.customerNumber = [];
        this.siteName = { type: 0, value: null };
        this.siteNumber = null;
        this.productStructure = { type: 0, value: null };
        this.notes = { type: 0, value: null,notes:null };
    }
}
export interface SearchNumber {
    searchNumberValue: string;
}

export interface serialSearchType {
    type: number | null;
    value: null;
    notes?: null;
}
