import { AdvancedSearch } from './advanced-search';
export class SerialCard {
      searchType?: number;
      searchText?: string;
      searchField?: number;
      advancedSearch: AdvancedSearch;
      constructor() {
            this.searchType = 1;
            this.searchText = '';
            this.searchField = 1;
            this.advancedSearch = new AdvancedSearch();
      }
}