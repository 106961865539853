import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractSecurityDirective } from './abstract-security.directive';
import { SecurityService } from '../services/security.service';

@Directive({
  selector: '[emrNoPermission]'
})
export class NoPermissionDirective extends AbstractSecurityDirective {

  permissionName = '';

  @Input() set emrNoPermission(name: string) {
    this.permissionName = name;
    this.changed();
  }

  constructor(securityService: SecurityService, templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(securityService, templateRef, viewContainerRef);
  }

  allowed(): boolean {
    return !this.user.hasPermission(this.permissionName);
  }
}
