import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonApiService } from './common-api.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private apiService: CommonApiService) {}

  getVersion(): Observable<any> {
    return this.apiService.get('assets/version.json');
  }
}
