export class ModuleParams {
  moduleType: string;
  officeName: string;
  xRefId: number;

  constructor();
  constructor (moduleType: string, xRefId: number, officeName: string);
  constructor (moduleType?: string, xRefId?: number, officeName?: string) {
    this.moduleType = moduleType!;
    this.officeName = officeName!;
    this.xRefId = xRefId!;
  }

  public setModuleParamsFromKeys(keys: string[]) {
    keys.forEach((element) => {
      const keyAndValue = element.split(":");
      if (keyAndValue.length === 2) {
        switch (keyAndValue[0].trim()) {
          case "moduleType":
            this.moduleType = keyAndValue[1].trim();
            break;
          case "officeName":
            this.moduleType = keyAndValue[1].trim();
            break;
          case "xRefId":
            this.xRefId = Number(keyAndValue[1].trim());
            break;
          default:
            break;
        }
      }
    });
  }
}
