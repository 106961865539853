<div class="container">
    <div class="px-2">
        <div class="row">
            <p class="h1">Do Not Use Browser Navigation Buttons</p>
        </div>
        <!-- <div class="browser-content">
            <ul>
                <li>Use  <span style="color: #004B8D;">breadcrumb navigation</span> to go back/forth instead of using browser</li>
                <br>
            </ul>
            <div > 
                <span> Navigation (back) button to
                    <span style="color: #B3261E;"> avoid data loss</span></span>
            </div>
        </div> -->
        <div class="browser-content">
            <div class="iconSection">
                <img src="../../../../../assets/warning-alert.svg" alt="alert">
            </div>
            <div class="detail">
                <p>Use <span class="text-primary">breadcrumb navigation</span> to go back/forth instead of using browser</p>
                <p>Navigation (back) button to <span class="text-danger">avoid data loss</span></p>
            </div>
            
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-btn buttons">
            <div mat-dialog-actions>
                <button mat-dialog-close class="btn-create" type="button">OK</button>
            </div>
        </div>
    </div>

</div>