<div [ngClass]="getWrapperClasses()">
    <select [ngClass]="getSelectClasses()" [name]="name" (change)="onChange($event)">
        @if (noneSelected() && placeHolder !== "")
        {
            <option value="" disabled [selected]="true">{{placeHolder}}</option>
        }
        <ng-container *ngFor="let option of options">
            <option [class]="option.cssClass" [value]="option.value" [selected]="option.value === selectedValue">{{ option.label }}</option>
        </ng-container>
    </select>
    <img *ngIf="showChevron" [src]="selectChevronDownIcon" class="absolute top-2.5 right-2 pointer-events-none"
        alt="Chevron" [ngClass]="getChevronClasses()" />
</div>