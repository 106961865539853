import { Injectable, OnInit } from "@angular/core";
import { ConfirmationPopUp, Construction, Component as ConComponent, ComponentSequence } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { DialogType } from "../../enums/common";
import { SelectProductAction, ProductFamily, ProductType, ProductValidationState } from "../../enums/buildassembly";
import { ProductLineTreeService } from "./product-line-tree.service";
import { IProductLine } from "../../models/productline-data.model";

@Injectable({
    providedIn: 'root'
})

export class ProductFamilyValidationService {
    componentSequences: ComponentSequence[] = [];
    inflatedProductLines: IProductLine[] = [];

    constructor(private _productLineTreeService: ProductLineTreeService) {

    }

    initializeProductLineTreeData() {
        this._productLineTreeService.productLines.subscribe((productLineResponse: any) => {
            this.inflatedProductLines = this._productLineTreeService.getInflateProductLines(productLineResponse.ProductLine);
        });
    }

    initializeProductLineInfo(construction: Construction): Construction {
        let productLine: IProductLine;

        construction.components.forEach((comp: ConComponent) => {
            productLine = this.inflatedProductLines.find((pl: IProductLine) => pl.productLineId === comp.productLineId)!;
            comp.instruction = productLine.instruction; //=== "Yes" ? true : false;
            comp.obsolete = productLine.obsolete === "Yes" ? true : false;
            comp.obsoleteQuoteDate = productLine.obsoleteQuoteDate;
            comp.obsoleteOrderDate = productLine.obsoleteOrderDate;
            comp.jdePartNumber = productLine.jdePartNumber;
            comp.mtgProductLineId = productLine.mtgProductLineId;
            comp.isRegulator = productLine.isRegulator;
        });

        return construction;
    }

    initializeProductTypeInfo(construction: Construction): Construction {
        construction.hasValve = false;
        construction.hasActuator = false;
        construction.hasValveActuator = false;
        construction.hasPositioner = false;
        construction.hasFactoryMounting = false;

        construction.hasController = false;
        construction.hasDesuperheater = false;
        construction.hasIndicator = false;
        construction.hasLevelSensor = false;
        construction.hasTransducer = false;

        construction.hasDirectOperatedRegulator = false;
        construction.hasDirectOperatedReliefValve = false;
        construction.hasPilotOperatedRegulator = false;
        construction.hasPilotOperatedReliefValve = false;
        construction.hasPilotOperatedRegulatorTemperature = false;
        construction.hasTankBlanketingVaporRecovery = false;
        construction.hasPressureLoadedRegulator = false;

        return construction;
    }

    AssignProductTypeInfo(construction: Construction, componentOnAction?: ConComponent, selectProductAction?: SelectProductAction): Construction {
        construction = this.initializeProductTypeInfo(construction);
        construction = this.initializeProductLineInfo(construction);

        let countDirectOperatedRegulator: number = 0;
        let countDirectOperatedReliefValve: number = 0;
        let countPilotOperatedRegulator: number = 0;
        let countPilotOperatedReliefValve: number = 0;
        let countPilotOperatedRegulatorTemperature: number = 0;
        let countTankBlanketingVaporRecovery: number = 0;
        let countPressureLoadedRegulator: number = 0;

        if (construction.components.length <= 0) {
            construction.productFamily = ProductFamily.None;
        } else {
            construction.components.forEach((comp: ConComponent) => {
                switch (comp.productType) {
                    case ProductType.Valve:
                        construction.hasValve = true;
                        break;
                    case ProductType.ValveActuator:
                        construction.hasValveActuator = true;
                        break;
                    case ProductType.Actuator:
                        construction.hasActuator = true;
                        break;
                    case ProductType.Controller:
                        construction.hasController = true;
                        break;
                    case ProductType.Desuperheater:
                        construction.hasDesuperheater = true;
                        break;
                    case ProductType.Indicator:
                        construction.hasIndicator = true;
                        break;
                    case ProductType.LevelSensor:
                        construction.hasLevelSensor = true;
                        break;
                    case ProductType.Positioner:
                        construction.hasPositioner = true;
                        break;
                    case ProductType.Transducer:
                        construction.hasTransducer = true;
                        break;
                    case ProductType.FactoryMountings:
                        construction.hasFactoryMounting = true;
                        break;
                    case ProductType.DirectOperatedRegulator:
                        countDirectOperatedRegulator++;
                        if (countDirectOperatedRegulator == 1) {
                            if (construction.productFamily !== ProductFamily.DirectOperatedRegulator) {
                                construction.hasDirectOperatedRegulator = true;
                            }
                        } else {
                            construction.hasDirectOperatedRegulator = true;
                        }
                        break;
                    case ProductType.DirectOperatedReliefValve:
                        countDirectOperatedReliefValve++;
                        if (countDirectOperatedReliefValve == 1) {
                            if (construction.productFamily !== ProductFamily.DirectOperatedReliefValve) {
                                construction.hasDirectOperatedReliefValve = true;
                            }
                        } else {
                            construction.hasDirectOperatedReliefValve = true;
                        }
                        break;
                    case ProductType.PilotOperatedRegulator:
                        countPilotOperatedRegulator++;
                        if (countPilotOperatedRegulator == 1) {
                            if (construction.productFamily !== ProductFamily.PilotOperatedRegulator) {
                                construction.hasPilotOperatedRegulator = true;
                            }
                        } else {
                            construction.hasPilotOperatedRegulator = true;
                        }
                        break;
                    case ProductType.PilotOperatedReliefValve:
                        countPilotOperatedReliefValve++;
                        if (countPilotOperatedReliefValve === 1) {
                            if (construction.productFamily !== ProductFamily.PilotOperatedReliefValve) {
                                construction.hasPilotOperatedReliefValve = true;
                            }
                        } else {
                            construction.hasPilotOperatedReliefValve = true;
                        }
                        break;
                    case ProductType.PilotOperatedRegulatorTemperature:
                        countPilotOperatedRegulatorTemperature++;
                        if (countPilotOperatedRegulatorTemperature == 1) {
                            if (construction.productFamily !== ProductFamily.PilotOperatedRegulatorTemperature) {
                                construction.hasPilotOperatedRegulatorTemperature = true;
                            }
                        } else {
                            construction.hasPilotOperatedRegulatorTemperature = true;
                        }
                        break;
                    case ProductType.TankBlanketingVaporRecovery:
                        countTankBlanketingVaporRecovery++;
                        if (countTankBlanketingVaporRecovery == 1) {
                            if (construction.productFamily !== ProductFamily.TankBlanketingVaporRecovery) {
                                construction.hasTankBlanketingVaporRecovery = true;
                            }
                        } else {
                            construction.hasTankBlanketingVaporRecovery = true;
                        }
                        break;
                    case ProductType.PressureLoadedRegulator:
                        countPressureLoadedRegulator++;
                        if (countPressureLoadedRegulator == 1) {
                            if (construction.productFamily !== ProductFamily.PressureLoadedRegulator) {
                                construction.hasPressureLoadedRegulator = true;
                            }
                        } else {
                            construction.hasPressureLoadedRegulator = true;
                        }
                        break;
                }
            });
        }

        return construction;
    }

    validateInstructionMeta(componentOnAction: ConComponent): ConfirmationPopUp {
        let productValidationState: ProductValidationState;
        let confirmationPopUp: ConfirmationPopUp = new ConfirmationPopUp();
        let message: string = "";

        if (componentOnAction.instruction) {
            productValidationState = ProductValidationState.InstructionMeta;
            //message = "Item: " + componentOnAction.productLineName;
            message = message + componentOnAction.instruction!;
            message = message + "<br><br>Are you sure you'd like to add " + componentOnAction.productLineName + " ?";
            confirmationPopUp.dialogType = DialogType.ConfirmationDialog;
            confirmationPopUp.message = message;
            confirmationPopUp.isModelWarningMessage = true;
        }

        return confirmationPopUp;
    }

    validate(construction: Construction, componentOnAction: ConComponent, selectProductAction: SelectProductAction): ConfirmationPopUp {
        let productValidationState: ProductValidationState = ProductValidationState.None;
        let mtgParent: ConComponent = new ConComponent();
        let confirmationPopUp: ConfirmationPopUp = new ConfirmationPopUp();

        //Product Fmaily Validations
        if (selectProductAction === SelectProductAction.Add) {
            if (this.isRegualtorFamily(componentOnAction.productFamilyUI)) {
                if (construction.productFamily !== ProductFamily.None && construction.regulatorFamily !== "") {
                    productValidationState = ProductValidationState.AddingRegulatorToOthers;
                }
            }
            if (componentOnAction.productFamilyUI !== ProductFamily.None) {
                switch (construction.productFamily) {
                    case ProductFamily.None:
                        construction.productFamily = componentOnAction.productFamilyUI;
                        construction.regulatorFamily = componentOnAction.productFamilyUI;
                        break;
                    case ProductFamily.BaumannAny:
                        if (construction.productFamily === ProductFamily.BaumannAny || componentOnAction.productFamilyUI === ProductFamily.BaumannSlidingStem || componentOnAction.productFamilyUI === ProductFamily.BaumannRotary) {
                            construction.productFamily = componentOnAction.productFamilyUI;
                        } else {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                    case ProductFamily.BaumannRotary:
                        if (componentOnAction.productFamilyUI === ProductFamily.FisherAny || componentOnAction.productFamilyUI === ProductFamily.BaumannAny || componentOnAction.productFamilyUI === ProductFamily.BaumannRotary) {
                            //construction.productFamily = componentOnAction.productFamilyUI;
                        } else {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                    case ProductFamily.BaumannSlidingStem:
                        if (componentOnAction.productFamilyUI === ProductFamily.FisherAny || componentOnAction.productFamilyUI === ProductFamily.BaumannAny || componentOnAction.productFamilyUI === ProductFamily.BaumannSlidingStem) {
                            //construction.productFamily = componentOnAction.productFamilyUI;
                        } else {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                    case ProductFamily.FisherAny:
                        if (construction.productFamily === ProductFamily.FisherAny || componentOnAction.productFamilyUI === ProductFamily.FisherSlidingStem || componentOnAction.productFamilyUI === ProductFamily.FisherRotary) {
                            construction.productFamily = componentOnAction.productFamilyUI;
                        } else {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                    case ProductFamily.FisherRotary:
                        if (componentOnAction.productFamilyUI === ProductFamily.FisherAny || componentOnAction.productFamilyUI === ProductFamily.FisherRotary) {
                            //construction.productFamily = componentOnAction.productFamilyUI;
                        } else {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                    case ProductFamily.FisherSlidingStem:
                        if (componentOnAction.productFamilyUI === ProductFamily.FisherAny || componentOnAction.productFamilyUI === ProductFamily.FisherSlidingStem) {
                            //construction.productFamily = componentOnAction.productFamilyUI;
                        } else {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                    case ProductFamily.Controller:
                        if (componentOnAction.productFamilyUI !== ProductFamily.Controller) {
                            productValidationState = ProductValidationState.ControllerCheckFailed;
                        };
                        break;
                    case ProductFamily.Desuperheater:
                        if (componentOnAction.productFamilyUI !== ProductFamily.Desuperheater) {
                            productValidationState = ProductValidationState.DesuperheaterCheckFailed;
                        };
                        break;
                    case ProductFamily.Indicator:
                        if (componentOnAction.productFamilyUI !== ProductFamily.Indicator) {
                            productValidationState = ProductValidationState.IndicatorCheckFailed;
                        };
                        break;
                    case ProductFamily.LevelSensor:
                        if (componentOnAction.productFamilyUI !== ProductFamily.LevelSensor) {
                            productValidationState = ProductValidationState.LevelSensorCheckFailed;
                        };
                        break;
                    case ProductFamily.Transducer:
                        if (componentOnAction.productFamilyUI !== ProductFamily.Transducer) {
                            productValidationState = ProductValidationState.TransducerCheckFailed;
                        };
                        break;
                    case ProductFamily.DirectOperatedRegulator:
                    case ProductFamily.DirectOperatedReliefValve:
                    case ProductFamily.PilotOperatedRegulator:
                    case ProductFamily.PilotOperatedReliefValve:
                    case ProductFamily.PilotOperatedRegulatorTemperature:
                    case ProductFamily.TankBlanketingVaporRecovery:
                    case ProductFamily.PressureLoadedRegulator:
                        if (componentOnAction.productFamilyUI !== construction.productFamily) {
                            productValidationState = ProductValidationState.IncorrectProductFamily;
                        }
                        break;
                }
            }
        }

        if (productValidationState === ProductValidationState.None) {
            //Obsolete Order Date onHold
            if (componentOnAction.obsoleteOrderDate) {
                productValidationState = ProductValidationState.obsoleteOrderDate;
            }

            if (componentOnAction.obsoleteQuoteDate) {
                productValidationState = ProductValidationState.obsoleteQuoteDate;
            }
        }

        if (selectProductAction === SelectProductAction.Add) {
            if (productValidationState === ProductValidationState.IncorrectProductFamily) {
                if (this.isRegualtorFamily(componentOnAction.productFamilyUI)) {
                    productValidationState = ProductValidationState.AddingRegulatorToOthers;
                }
            }
        }

        if (selectProductAction === SelectProductAction.Add || selectProductAction === SelectProductAction.None) {
            let countLimit: number = 0;
            if (componentOnAction.productFamilyUI === construction.regulatorFamily) {
                countLimit = 1;
            }
            switch (componentOnAction.productType) {
                case ProductType.CLS:
                case ProductType.CLSValve:
                    let parentComponents: ConComponent[] = [];
                    parentComponents = this.getCLSParents(construction);
                    if (parentComponents.length === 0) {
                        productValidationState = ProductValidationState.CLSWithOutParent;
                    }
                    if (componentOnAction.productType === ProductType.CLSValve) {
                        if (!construction.hasValve && !construction.hasValveActuator) {
                            productValidationState = ProductValidationState.ValveCLSWithOutParent;
                        }
                    }
                    break;
                case ProductType.Valve:
                    if ((construction.hasPositioner || construction.hasController || construction.hasTransducer || construction.hasLevelSensor) && !construction.hasActuator) {
                        productValidationState = ProductValidationState.DVCWithOutActuator;
                    }
                    if (construction.hasValve || construction.hasValveActuator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.ValveActuator:
                    if (construction.hasValve || construction.hasValveActuator || construction.hasActuator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.Actuator:
                    if (construction.hasActuator || construction.hasValveActuator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.Controller:
                    if (construction.hasPositioner || construction.hasController || construction.hasTransducer || construction.hasLevelSensor) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.Desuperheater:
                    if (construction.hasDesuperheater) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.Indicator:
                    if (construction.hasIndicator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.LevelSensor:
                    if (construction.hasLevelSensor) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.Positioner:
                    if (construction.hasPositioner || construction.hasController || construction.hasTransducer || construction.hasLevelSensor) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    if (construction.hasValve && !construction.hasActuator) {
                        productValidationState = ProductValidationState.DVCWithOutActuator;
                    }
                    break;
                case ProductType.Transducer:
                    if (construction.hasPositioner || construction.hasController || construction.hasTransducer || construction.hasLevelSensor) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.FactoryMountings:
                    if (construction.hasFactoryMounting) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.DirectOperatedRegulator:
                    if (construction.hasDirectOperatedRegulator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.DirectOperatedReliefValve:
                    if (construction.hasDirectOperatedReliefValve) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.PilotOperatedRegulator:
                    if (construction.hasPilotOperatedRegulator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.PilotOperatedReliefValve:
                    if (construction.hasPilotOperatedReliefValve) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.PilotOperatedRegulatorTemperature:
                    if (construction.hasPilotOperatedRegulatorTemperature) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.TankBlanketingVaporRecovery:
                    if (construction.hasTankBlanketingVaporRecovery) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
                case ProductType.PressureLoadedRegulator:
                    if (construction.hasPressureLoadedRegulator) {
                        productValidationState = ProductValidationState.DuplicateProducts;
                    }
                    break;
            }
        } else if (selectProductAction === SelectProductAction.Remove) {
            //To remove the DVC while removing the Factory mounting
            if (componentOnAction.productType === ProductType.FactoryMountings) {
                if (construction.hasActuator || construction.hasValveActuator) {
                    mtgParent = construction.components.find((comp: ConComponent) => comp.mtgProductLineId === componentOnAction.productLineId)!;
                    if (mtgParent) {
                        productValidationState = ProductValidationState.MtgWithoutDVC;
                    }
                }
            } else {
                mtgParent = construction.components.find((comp: ConComponent) => comp.mtgProductLineId === componentOnAction.productLineId)!;
                if (mtgParent) {
                    productValidationState = ProductValidationState.MtgWithoutDVC;
                }
            }

            if (construction.productFamily === componentOnAction.productFamilyUI) {
                if (construction.components.length === 0) {
                    construction.productFamily = ProductFamily.None;
                } else {
                    let firstComponent: ConComponent;
                    firstComponent = construction.components.find((comp: ConComponent) => (comp.guid !== componentOnAction.guid))!;
                    if (firstComponent) {
                        construction.productFamily = firstComponent.productFamilyUI;
                        if (this.isRegualtorFamily(firstComponent.productFamilyUI)) {
                            construction.regulatorFamily = firstComponent.productFamilyUI;
                        }
                    }
                }
            }

            switch (componentOnAction.productType) {
                case ProductType.Actuator:
                    if ((construction.hasPositioner || construction.hasController || construction.hasTransducer || construction.hasLevelSensor) && construction.hasValve) {
                        productValidationState = ProductValidationState.DVCWithOutActuator;
                    }
                    break;
            }
        }

        let message: string = "";
        confirmationPopUp.productValidationState = productValidationState;
        switch (productValidationState) {
            case ProductValidationState.DuplicateProducts:
                if (selectProductAction === SelectProductAction.Add) {
                    if (componentOnAction.productType === ProductType.ValveActuator) {
                        if (construction.hasValveActuator) {
                            confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because there is already a 'ValveActuator' for this configuration.";
                        }
                        if (construction.hasValve && construction.hasActuator) {
                            confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because there is already a 'Valve & Actuator' for this configuration.";
                        }
                        if (construction.hasValve) {
                            confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because there is already a 'Valve' for this configuration.";
                        }
                        if (construction.hasActuator) {
                            confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because there is already a 'Actuator' for this configuration.";
                        }
                    } else if (componentOnAction.productType === ProductType.Controller || componentOnAction.productType === ProductType.LevelSensor || componentOnAction.productType === ProductType.Positioner || componentOnAction.productType === ProductType.Transducer) {
                        confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productType + "', because there is already a 'Contoller / Level Sensor / Positioner / Transducer' for this configuration.";
                    } else {
                        confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because there is already a '" + componentOnAction.productType + "' for this configuration.";
                    }
                }
                break;
            case ProductValidationState.obsoleteQuoteDate:
                if (selectProductAction === SelectProductAction.Add) {
                    message = "No Quotes After: " + componentOnAction.obsoleteQuoteDate!;
                    message = message + "<br><br>Are you sure you'd like to add this obsolete product line: '" + componentOnAction.productLineName + "'?"
                    confirmationPopUp.dialogType = DialogType.ConfirmationDialog;
                    confirmationPopUp.message = message;
                    confirmationPopUp.isModelWarningMessage = true;
                }
                break;
            case ProductValidationState.obsoleteOrderDate:
                if (selectProductAction === SelectProductAction.Add) {
                    message = "No Orders After: " + componentOnAction.obsoleteOrderDate!;
                    message = message + "<br><br>Are you sure you'd like to add this obsolete product line: '" + componentOnAction.productLineName + "'?"
                    confirmationPopUp.dialogType = DialogType.ConfirmationDialog;
                    confirmationPopUp.message = message;
                    confirmationPopUp.isModelWarningMessage = true;
                }
                break;
            case ProductValidationState.DVCWithOutActuator:
                if (componentOnAction.productType === ProductType.Valve) {
                    confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because it is not configurable with at least one other component in the item.";
                } else {
                    confirmationPopUp.message = "'Controller / Positioner / Transducer / Level' can only be added to an empty Configuration (just containing accessories) or Configuration that has Actuator.";
                }
                break;
            case ProductValidationState.IncorrectProductFamily:
                if (selectProductAction === SelectProductAction.Add) {
                    confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because it is not from the same product family as the other components in the item.";
                }
                break;
            case ProductValidationState.CLSWithOutParent:
                if (selectProductAction === SelectProductAction.Add) {
                    confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because Item does not contain any Non-Service components.";
                }
                break;
            case ProductValidationState.ValveCLSWithOutParent:
                if (selectProductAction === SelectProductAction.Add) {
                    confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "', because Item does not contain any Valve component.";
                }
                break;
            case ProductValidationState.MtgWithoutDVC:
                if (selectProductAction === SelectProductAction.Remove) {
                    confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "' , because there is already an associated Product '" + mtgParent.productLineName + "' for this configuration.";
                }
                break;
            case ProductValidationState.AddingRegulatorToOthers:
                if (selectProductAction === SelectProductAction.Add) {
                    confirmationPopUp.message = "A main body component already exists for this item. Do you want to add the '" + componentOnAction.productLineName + "' component as an Accessory? (Components added as Accessories can not be sized.)"
                    confirmationPopUp.dialogType = DialogType.ConfirmationDialog;
                }
                break;
            case ProductValidationState.ControllerCheckFailed:
            case ProductValidationState.DesuperheaterCheckFailed:
            case ProductValidationState.IndicatorCheckFailed:
            case ProductValidationState.TransducerCheckFailed:
            case ProductValidationState.LevelSensorCheckFailed:
                if (selectProductAction === SelectProductAction.Add) {
                    confirmationPopUp.message = "Cannot " + this.getSelectProductActionName(selectProductAction) + " a component of type: '" + componentOnAction.productLineName + "' because it is not configurable with at least one other component in the item."
                    confirmationPopUp.dialogType = DialogType.SimpleDilaog;
                }
                break;
        }
        return confirmationPopUp;
    }

    getCLSParents(construction: Construction): ConComponent[] {
        let parentComponents: ConComponent[] = [];
        parentComponents = construction.components.filter((comp: ConComponent) => comp.productType !== ProductType.CLS && comp.productType !== ProductType.CLSValve && comp.productType !== ProductType.ItemLevelService && comp.productType !== ProductType.ProcessLevel);

        return parentComponents;
    }

    isRegualtorFamily(productFamily: ProductFamily): boolean {
        return productFamily === ProductFamily.DirectOperatedRegulator
            || productFamily === ProductFamily.DirectOperatedReliefValve
            || productFamily === ProductFamily.PilotOperatedRegulator
            || productFamily === ProductFamily.PilotOperatedReliefValve
            || productFamily === ProductFamily.PilotOperatedRegulatorTemperature
            || productFamily === ProductFamily.TankBlanketingVaporRecovery
            || productFamily === ProductFamily.PressureLoadedRegulator;
    }

    sortComponents(construction: Construction): Construction {
        this.initializeComponentSequence();

        let parentComponents: ConComponent[] = [];
        let childComponents: ConComponent[] = [];

        parentComponents = construction.components.filter((comp: ConComponent) => (comp.productType !== ProductType.CLS && comp.productType !== ProductType.CLSValve));
        childComponents = construction.components.filter((comp: ConComponent) => (comp.productType === ProductType.CLS || comp.productType === ProductType.CLSValve));

        parentComponents.forEach((comp: ConComponent) => {
            let componentSequence: ComponentSequence;
            componentSequence = this.componentSequences.find(x => x.productType === comp.productType)!;
            if (componentSequence) {
                comp.productTypeSequence = componentSequence.id;
            }
        });

        parentComponents.sort((a, b) => {
            return (a.productTypeSequence - b.productTypeSequence);
        });

        let newComponents: ConComponent[] = [];
        parentComponents.forEach((parentComp: ConComponent) => {
            let comps: ConComponent[] = [];
            comps = childComponents.filter((childComp: ConComponent) => childComp.parent?.guid === parentComp.guid);
            newComponents.push(parentComp);
            newComponents = [...newComponents, ...comps];
        });
        construction.components = newComponents;

        return construction;
    }

    getSelectProductActionName(selectProductAction: SelectProductAction): string {
        switch (selectProductAction) {
            case SelectProductAction.Add:
                return "'Add'"
                break;
            case SelectProductAction.Remove:
                return "'Remove'"
                break;
            case SelectProductAction.RemoveAll:
                return "'Remove All'"
                break;
            case SelectProductAction.None:
                return "'None'"
                break;
        }
    }

    initializeComponentSequence() {
        let id: number = 0;
        let componentSequence: ComponentSequence
        componentSequence = new ComponentSequence(id++, ProductType.Valve);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.CLSValve);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.ValveActuator);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Desuperheater);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Actuator);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Positioner);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.FactoryMountings);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.DirectOperatedRegulator);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.DirectOperatedReliefValve);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.PilotOperatedRegulator);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.PilotOperatedRegulatorTemperature);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.PilotOperatedReliefValve);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.TankBlanketingVaporRecovery);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.PressureLoadedRegulator);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Indicator);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Transducer);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Controller);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.Accessories);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.None);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.ProcessLevel);
        this.componentSequences.push(componentSequence);
        componentSequence = new ComponentSequence(id++, ProductType.ItemLevelService);
        this.componentSequences.push(componentSequence);
    }
}