<div class="app-background-color" id="wrapper">
    
    <div class="init">
        <app-header></app-header>
        <div class="router-body">
            <app-layout>
            <router-outlet></router-outlet>
          </app-layout></div>
        <app-footer></app-footer>
        
    </div>
</div>
<div *ngIf="isLoading" class="loading-indicator">
    <div>
        <mat-spinner style="top:-10px;" [diameter]="40"></mat-spinner>
        <span class="loading-text">Loading...</span>
    </div>
</div>