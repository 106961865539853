import { UiVars } from "./uivars";
import { ConditionName } from "./condition.name";
import { DesignAttribute } from "./design.attribute";
import { RequestCalculateData } from "./request.calculate.data";
import { SizingMethod } from "./sizing.method";
import { VariableData } from "./variable.data";
import { RequestCalculateFlowCatalogData } from "../catalog/request.calculate.flowcatalog.data";

export class RequestCalculateDataClass implements RequestCalculateData {
    variableList: VariableData[];
    designVariables: DesignAttribute[];
    contextId: number;
    conditionNames: ConditionName[];
    sizingMethod: SizingMethod | null;
    catalogHit: RequestCalculateFlowCatalogData | null; 
    uiVars: UiVars;
    serviceDescription: DesignAttribute | null = null
    constructor(
        variableList: VariableData[],
        designVariables: DesignAttribute[],
        contextId: number,
        conditionNames: ConditionName[],
        sizingMethod: SizingMethod | null,
        catalogHit: RequestCalculateFlowCatalogData | null = null,
        UiVars: UiVars,
        serviceDescription : DesignAttribute | null = null
    ) {
        this.variableList = variableList;
        this.designVariables = designVariables;
        this.contextId = contextId;
        this.conditionNames = conditionNames;
        this.sizingMethod = sizingMethod;
        this.catalogHit = catalogHit;
        this.uiVars = UiVars;
        this.serviceDescription = serviceDescription;
    }

}