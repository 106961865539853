import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush, // Change detection strategy
  styleUrl: './toggle.component.scss',
})
export class ToggleComponent {
  @Input() id!: string;
  @Input() leftLabel!: string;
  @Input() leftLabelClasses: string[] = [];
  @Input() label!: string;
  @Input() isChecked: boolean = false;
  @Input() dotClasses: string[] = [];
  @Input() labelClasses: string[] = [];
  @Output() isCheckedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  toggleSwitch(): void {
    console.log('isChecked', this.isChecked);
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
