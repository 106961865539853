import { ConfigItError } from "src/app/shared/enums/buildassembly";

export class ErrorInfo{
    error: ConfigItError;
    hasError: boolean;
    errorMessage: string;
    hasConflict: boolean;

    constructor(){
        this.error = ConfigItError.None;
        this.hasError = false;
        this.errorMessage = "";
        this.hasConflict = false;
    }
}