import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AbstractSecurityDirective } from "./abstract-security.directive";
import { SecurityService } from "../services/security.service";
import { ModuleParams } from "../models/module-params";

@Directive({
  selector: "[emrHasModuleEditPermission]",
})
export class HasModuleEditPermissionDirective extends AbstractSecurityDirective {

  moduleParams = new ModuleParams();

  // example:
  //  *emrHasModuleEditPermission="'moduleType:Project, xRefId:10010, officeName:FISHER CONTROLS'"
  @Input() set emrHasModuleEditPermission(keys: string) {
    const splitKeys = keys.split(",");
    this.moduleParams.setModuleParamsFromKeys(splitKeys);
    this.changed();
  }

  constructor(
    securityService: SecurityService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    super(securityService, templateRef, viewContainerRef);
  }

  allowed(): boolean {
    return this.user.hasModulePermission(this.moduleParams, true);
  }

}
