export class FlexSerialCard {
    serialNumber: string;
    pdfFileName: string;
    internal: boolean;
    preview: boolean;
    serialNumberType: string;
    constructor() {
        this.serialNumber = '';
        this.pdfFileName = '';
        this.internal = false;
        this.preview = false;
        this.serialNumberType = '';
    }
}