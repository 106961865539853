import { Pipe, PipeTransform } from '@angular/core';
import { ItemTypesArray } from '../../enums/mro/mro-serial-card';

@Pipe({
  name: 'itemNames',
})
export class ItemNamesPipe implements PipeTransform {

  transform(value: number): string {
    return ItemTypesArray.find((item) => Number(item.id) === value)?.label || '';
  }
}
