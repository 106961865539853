import { Session } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';

export class TagIdentifier {    
    userId:string; //Project workspace APIs are using Pascal case
    projectId: number;
    tagId: number;
    tagQuantity: number;
    tagName?: string;
    revisionId: number;
    revisionName?: string;
    constructionTypeId?: number;
    constructionType?: string;
    constructionVersionId?: number;
    constructionId: number;
    isPresentWorkspace?: boolean;

    sourceTagId?:number;
    destTagId?: number;    
    groupId?: number;
    effectivePriceDate?: Date;

    session: Session;

    constructor(){
        this.userId = "1";
        this.projectId = 0;
        this.tagId = 0;
        this.tagQuantity = 1;
        this.revisionId = 0;
        this.constructionTypeId = 0;
        this.constructionVersionId = 0;
        this.constructionId = 0;
        
        this.sourceTagId = 0;
        this.destTagId = 0;

        this.isPresentWorkspace = false;
        this.tagName = "";
        this.revisionName = "";
        this.constructionType = "";

        this.session = new Session();
    }

    Initialize(session: Session, projectId: number, tagId: number, taQuantity: number, revisionId: number, constructionTypeId: number, constructionVersionId: number, construictionId: number, tagName: string, revisionName: string, constructionType: string) {
        this.session = session;
        this.projectId = projectId;
        this.tagId = tagId;
        this.revisionId = revisionId;
        this.constructionTypeId = constructionTypeId;
        this.constructionVersionId = constructionVersionId;
        this.constructionId = construictionId;
        this.tagQuantity = taQuantity;
        this.tagName = tagName;
        this.revisionName = revisionName;
        this.constructionType = constructionType;
    }
}