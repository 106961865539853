export enum AttributeTab {
    Published = "Published",
    Special = "Special"
}

export enum AttributeType {
    Primary = 1,
    Secondary = 2,
    internel = 3
}

export enum AssignedBy {
    None = 0,
    User = 1,
    Sizing = 2,
    Complete = 3,
    Special = 4,
}

export enum AssignmentState {
    Assignable = 1,
    Forceable = 2,
    Blocked = 3,
    UserAssigned = 4,
    SystemAssigned = 5,
    CompleteAssigned = 6,
    SizingAssigned = 7,
    SpecialAssigned = 8,
    RuleBlocked = 9,
}

export enum ConfigureAction {
    updateComponentStructure = 1,
    dropDownChange = 2,
    setDefaults = 3,
    setAllDefaults = 4,
    clearDefaults = 5,
    clearAllDefaults = 6,
    clear = 7,
    clearAll = 8,
    clearSelection = 9,
    ResolveAttributes = 10,
    setAsSpecial = 11,
    clearSpecialValue = 12,
    undoSpecial = 13,
    turnOff = 14,
    turnOff_Prompt = 15,
    none = 16
}

export enum ProductValidationState {
    None = 0,
    DuplicateProducts = 1,
    InstructionMeta = 2,
    obsoleteQuoteDate = 3,
    obsoleteOrderDate = 4,
    IncorrectProductFamily = 5,
    DVCWithOutActuator = 6,
    CLSWithOutParent = 7,
    ValveCLSWithOutParent = 8,
    MtgWithoutDVC = 9,
    AddingRegulatorToOthers = 10,

    ControllerCheckFailed = 11,
    DesuperheaterCheckFailed = 12,
    IndicatorCheckFailed = 13,
    LevelSensorCheckFailed = 14,
    TransducerCheckFailed = 15,
}

export enum ProductFamily {
    None = "",
    BaumannAny = "BaumannAny",
    BaumannRotary = "BaumannRotary",
    BaumannSlidingStem = "BaumannSlidingStem",
    FisherAny = "FisherAny",
    FisherRotary = "FisherRotary",
    FisherSlidingStem = "FisherSlidingStem",
    DirectOperatedRegulator = "Direct Operated Regulator",
    DirectOperatedReliefValve = "Direct Operated Relief Valve",
    PilotOperatedRegulator = "Pilot Operated Regulator",
    PilotOperatedReliefValve = "Pilot Operated Relief Valve",
    PilotOperatedRegulatorTemperature = "Pilot Operated Regulator Temperature",
    TankBlanketingVaporRecovery = "Tank Blanketing/Vapor Recovery",
    PressureLoadedRegulator = "Pressure Loaded Regulator",

    Controller = "Controller",
    Desuperheater = "Desuperheater",
    Indicator = "Indicator",
    LevelSensor = "Level Sensor",
    Transducer = "Transducer",
}

export enum SelectProductAction {
    None = 0,
    Add = 1,
    Remove = 2,
    RemoveAll = 3
}

export enum ProductType {
    None = "",
    CLS = "CLS",
    ItemLevelService = "ILS",
    CLSValve = "CLSValve",
    ProcessLevel = "ProcessLevel",
    Valve = "Valve",
    ValveActuator = "ValveActuator",
    Actuator = "Actuator",
    Positioner = "Positioner",
    FactoryMountings = "FactoryMountings",
    Controller = "Controller",
    Desuperheater = "Desuperheater",
    Indicator = "Indicator",
    LevelSensor = "Level Sensor",
    Transducer = "Transducer",
    Regulator = "Regulator",
    DirectOperatedRegulator = "Direct Operated Regulator",
    DirectOperatedReliefValve = "Direct Operated Relief Valve",
    PilotOperatedRegulator = "Pilot Operated Regulator",
    PilotOperatedReliefValve = "Pilot Operated Relief Valve",
    PilotOperatedRegulatorTemperature = "Pilot Operated Regulator Temperature",
    TankBlanketingVaporRecovery = "Tank Blanketing/Vapor Recovery",
    PressureLoadedRegulator = "Pressure Loaded Regulator",
    Accessories = "Accessories"
}

export enum PriceGroupType {
    None = "",
    Item = "Item",
    Component = "Component",
    Base = "Base",
    Adder = "Adder",
    Special = "Special"
}

export enum PriceType {
    None = "",
    Standard = "Standard",
    Special = "Special"
}

export enum BrokenLinkType {
    None = 0,
    AssemblyValidationFailure = 1,
    IncompatibleAttributeValueSelection = 2,
    BrokenByUser = 3,
}

export enum ApplyType {
    Configure = 0,
    Load = 1,
    Constraints = 2
}

export enum ListNet {
    None = "",
    List = "List",
    Net = "Net",
    NonStdList = "Non-Std"
}

export enum ConfigItError {
    None = 0,
    SystemError = 1,
    CompilationError = 2,
    SpecialAssignment = 3
}

export enum CallBackAction {
    None = 0,
    CreateAlternate = 1,
    DuplicateTagRevision = 2,
}