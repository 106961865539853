import { AssignedBy, AttributeType, AttributeTab } from "src/app/shared/enums/buildassembly";
import { AttributeValue } from "./attributeValue";

export class Attribute {
    name?: string;
    values: AttributeValue[];
    id?: number;
    fqn?: string;
    attributeId?: number;
    value?: string;
    type?: string;
    assignedBy?: AssignedBy;
    attributeType?: AttributeType;
    componentName?: string;
    productLineName?: string;
    isBroken: boolean;
    specialValue?: string;

    //UI Specific
    attrValueId?: number;
    selectedOption?: AttributeTab;
    dropdownValues: AttributeValue[];
    isConflict: boolean;

    constructor() {
        this.values = [];
        this.dropdownValues = [];
    }
}