import { Injectable } from '@angular/core';
import { Config } from '../../../../../../shared/config';
import { Observable } from 'rxjs';
import { Construction, Session } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { TagIdentifier } from 'src/app/shared/models/project-worksapce/tagidentifier';
import { CommonApiService } from 'src/app/shared/services/common-api.service';


@Injectable({
  providedIn: 'root',
})
export class BuildAssemblyDataService {
  private config: Config;

  constructor(private apiService: CommonApiService) {
    this.config = new Config();
  }

  public getSession(): Observable<Session> {
    return this.apiService.getJSON(
      this.config.CONFIGIT_GETSESSION_API,
    );
  }

  public removeSession(payLoad: string) {
    return this.apiService.post(
      this.config.CONFIGIT_REMOVESESSION_API,
      JSON.stringify(payLoad)
    );
  }

  public fetchBuildAssembly(payLoad: TagIdentifier) {
    return this.apiService.post(
      this.config.POST_FETCH_BUILDASSEMBLY_API,
      payLoad
    );
  }

  saveBuildAssembly(payLoad: Construction): Observable<Construction> {
    return this.apiService.post(
      this.config.POST_SAVE_BUILDASSEMBLY_API,
      payLoad
    );
  } 

  public updateComponentStucure(payLoad: Construction) {
    return this.apiService.post(
      this.config.CONFIGIT_UPDATE_COMPONENT_STRUCTURE_API,
      payLoad
    );
  }

  public configure(payLoad: Construction) {
    return this.apiService.post(this.config.CONFIGIT_CONFIGURE_API, payLoad);
  }  

  public complete(payLoad: Construction) {
    return this.apiService.post(
      this.config.CONFIGIT_COMPLETE_API,
      payLoad
    );
  }

  public sepcials(payLoad: Construction) {
    return this.apiService.post(
      this.config.CONFIGIT_SPECIALS_API,
      payLoad
    );
  }

  public rePrice(payLoad: Construction) {
    return this.apiService.post(this.config.CONFIGIT_REPRICE_API, payLoad);
  }

}
