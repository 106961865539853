import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
CommonModule;
@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() theme: 'default' | 'outline' | 'outline-primary'| 'outline-green' = 'default';
  @Input() hasIcon: boolean = false;
  @Input() iconSrc: string = '';
  @Input() appendClassName: string = '';
  @Input() label: string = '';
  @Input() type: 'button' | 'submit' | 'reset' | 'split' = 'button';
  @Input() hasIconTemplate: boolean = false;
  @Input() iconPlacement: 'left' | 'right' = 'left';
  @Input() disabled = false;

  computeButtonClasses(): string {
    let classes = '';

    // Default button classes
    if(this.type !== 'split') {
      classes +=
      'hover:bg-[#F7F7F7] transition-all hover:transition-all duration-300 shrink-0 whitespace-nowrap px-2.5 py-2 bg-white rounded-lg justify-center items-center gap-2 inline-flex text-black border border-gray-100 text-gray-700 !text-xs !2xl:text-sm font-medium font-inter'; // Add your default classes here
    }

    // Theme-specific classes
    if (this.theme === 'outline') {
      classes += ' border-white !bg-transparent text-white'; // Add outline theme classes here
    }

    if (this.theme === 'outline-primary') {
      classes +=
        ' !bg-transparent border-green-primary text-xs 2xl:text-sm text-green-primary hover:!bg-green-primary hover:text-white'; // Add outline-primary theme classes here
    }
    if (this.theme === 'outline-green') {
      classes +=
        ' text-xs 2xl:text-sm px-3 py-2 font-semibold !text-[#00573D] border !border-[#00573D] rounded-lg bg-transparent hover:!bg-[#EDFEF9]'; // Add outline-green theme classes here
    }

    if (this.hasIcon) {
      classes += ' with-icon';
    }

    // Appended classes
    if (this.appendClassName) {
      classes += ' ' + this.appendClassName;
    }
    
    return classes;
  }
}
