import { BrokenLinkType } from "src/app/shared/enums/buildassembly";
export class BreakRule{
    guid: string;
    id: string;
    brokenType: BrokenLinkType;

    constructor(guid: string, id: string, brokenType: BrokenLinkType){
        this.guid = guid;
        this.id = id;
        this.brokenType = brokenType;
    }
}