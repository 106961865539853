import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faClock, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { RecentSearch } from 'src/app/shared/models/mro/recent-search';

@Component({
  selector: 'app-recent-search',
  templateUrl: './recent-search.component.html',
  styleUrl: './recent-search.component.scss'
})
export class RecentSearchComponent {

  faClock = faClock;
  faSearch = faMagnifyingGlass;
  faEdit = faPenToSquare
  @Input() searchResults: RecentSearch[];
  @Output() recentSelected = new EventEmitter<{ id: number, type: string }>();


  value: string = '';
  list: string[] = [];
  constructor() {
  }

  addItem(item: string) {
    this.list.push(item);
  }
  onSearch(index: number, type: string) {
    this.recentSelected.emit({ id: index, type });
  }
}
